import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {SpravaUctuStore} from '../../../Store/SpravaUctuStore';
import {SpravaUctuFilterModel} from '../model';

const initialState = {
    filter: {fulltext: ''},
    aktivni: true,
    activeTab: 0,
    aktivniOpenAPI: undefined,
} as SpravaUctuStore;

export const spravaUctuSlice = createSlice({
    name: 'spravaUctu',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSpravaUctuFilter: (state: SpravaUctuStore, {payload}: PayloadAction<SpravaUctuFilterModel>): SpravaUctuStore => ({
            ...state,
            filter: payload,
        }),
        setSpravaUctuAktivni: (state: SpravaUctuStore, {payload}: PayloadAction<boolean | undefined>): SpravaUctuStore => ({
            ...state,
            aktivni: payload,
        }),
        setSpravaUctuOpenAPIAktivni: (state: SpravaUctuStore, {payload}: PayloadAction<boolean | undefined>): SpravaUctuStore => ({
            ...state,
            aktivniOpenAPI: payload,
        }),
        setSpravaUctuChangeTab: (state: SpravaUctuStore, {payload}: PayloadAction<number>): SpravaUctuStore => ({
            ...state,
            activeTab: payload,
        }),
    },
});

export const {setSpravaUctuAktivni, setSpravaUctuChangeTab, setSpravaUctuFilter, setSpravaUctuOpenAPIAktivni} = spravaUctuSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const spravaUctu = (state: Store) => state.spravaUctu;
export const selectSpravaUctu = createSelector([spravaUctu], (spravaUctu) => spravaUctu);
