import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {ZadostiSopWebSelectedLeftMenuType} from '../model/ZadostiOstatniModel';
import {PrehledSopZadostiNeodeslaneFilter, PrehledSopZadostiOdeslaneFilter} from '../service/ZadostiOstatniService';
import {ZadostiOstatniStore} from '../store/ZadostiOstatniStore';

export const initialState = {
    selectedLeftMenuType: ZadostiSopWebSelectedLeftMenuType.ROZPRACOVANE,
    openPodatZadost: false,
    filter: undefined,
} as ZadostiOstatniStore;

export const zadostiOstatniSlice = createSlice({
    name: 'zadostiOstatni',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setZadostiSopWebSelectedLeftMenuType: (
            state: ZadostiOstatniStore,
            {payload}: PayloadAction<ZadostiSopWebSelectedLeftMenuType>,
        ): ZadostiOstatniStore => ({
            ...state,
            selectedLeftMenuType: payload,
        }),
        setZadostiSopWebPodatZadost: (state: ZadostiOstatniStore, {payload}: PayloadAction<boolean>): ZadostiOstatniStore => ({
            ...state,
            openPodatZadost: payload,
        }),
        setZadostiSopWebFilter: (
            state: ZadostiOstatniStore,
            {payload}: PayloadAction<PrehledSopZadostiOdeslaneFilter | PrehledSopZadostiNeodeslaneFilter | undefined>,
        ): ZadostiOstatniStore => ({
            ...state,
            filter: payload,
        }),
    },
});

export const {setZadostiSopWebSelectedLeftMenuType, setZadostiSopWebPodatZadost, setZadostiSopWebFilter} = zadostiOstatniSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example, `useSelector((state: RootState) => state.counter.value)`
export const zadostiOstatni = (state: Store) => state.zadosti.ostatni;
export const selectZadostiElektrina = createSelector([zadostiOstatni], (zadostiOstatni) => zadostiOstatni);
