import {ElectricityIcon, GasIcon} from '@eon.cz/apollo13-frontend-common';
import {Komodita} from '@eon.cz/apollo13-graphql';
import * as React from 'react';

type Props = {
    readonly type: Komodita | null | undefined;
};

/**
 * Zobrazení ikony pro danou komoditu
 */
export const KomoditaIcon: React.FC<Props> = ({type}) => (type === Komodita.PLYN ? <GasIcon /> : <ElectricityIcon />);
