import {useApolloClient} from '@apollo/client';
import {Div} from '@eon.cz/apollo13-frontend-common';
import {useMatches} from '@eon.cz/apollo13-frontend-common/lib/utils/CommonUtils';
import {Warning as WarningIcon} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import axios from 'axios';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {useAuthAction} from '../actions';

type Props = {
    readonly email: string | undefined | null;
    readonly onClose: () => void;
    readonly onClickNo: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.custom.orange.main,
        marginRight: theme.spacing(2),
        width: 80,
        height: 80,
    },
    text: {
        flex: '1',
    },
    button: {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        opacity: 1,
        marginRight: theme.spacing(3),
        '&:disabled': {
            color: theme.palette.common.white,
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    strip: {
        width: '100%',
        background: theme.palette.common.white,
        color: theme.palette.secondary.dark,
        padding: '10px 0',
        textAlign: 'center',
        position: 'relative',
        margin: `${theme.spacing(2)}px 0px`,
        borderRadius: 35,
    },
}));

/**
 * Komponenta pro přihlášení zobrazení síly hesla v případě jiného než silného hesla
 */
export const LoginPasswordStrengthDialog = ({email, onClickNo, onClose}: Props) => {
    const classes = useStyles({});
    const matches = useMatches();
    const [resetSent, setResetSent] = useState(false);
    const client = useApolloClient();
    const {logout} = useAuthAction();

    const handleSubmitResetHesla = () => {
        axios.post(`${window.location.origin}/api/createResetHeslaPozadavek`, {email}).then(() => setResetSent(true));
    };

    const handleLoginRedirect = () => {
        // make sure to clear token from cookie
        onClose();
        logout(client);
    };

    return (
        <Dialog open={true}>
            <DialogTitle>
                <FormattedMessage id={Lang.PASSWORD_STRENGTH_TITLE} />
            </DialogTitle>
            <DialogContent className={classes.content}>
                {!resetSent && (
                    <>
                        <WarningIcon className={classes.icon} />
                        <div className={classes.text}>
                            <Typography>
                                <FormattedMessage id={Lang.PASSWORD_STRENGTH_MESSAGE} />
                            </Typography>
                        </div>
                    </>
                )}
                {resetSent && (
                    <Div>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_ODESLANO} />
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage
                                id={Lang.RESET_HESLA_POZADAVEK_ODESLANO_TEXT}
                                values={{
                                    email,
                                }}
                            />
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_ODESLANO_UPOZORNENI} />
                        </Typography>
                    </Div>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} sx={{textAlign: 'right'}}>
                    {!resetSent ? (
                        <>
                            <Grid item xs={12} sm={9}>
                                <Button variant="outlined" onClick={handleSubmitResetHesla} fullWidth={matches}>
                                    <FormattedMessage id={Lang.BUTTON_ZMENA_HESLA} />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button variant="contained" onClick={onClickNo} fullWidth={matches}>
                                    <FormattedMessage id={Lang.RESET_HESLA_ZPET} />
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Button variant="outlined" onClick={handleLoginRedirect} fullWidth={matches}>
                                <FormattedMessage id={Lang.LOGIN_ZPET_NA_PRIHLASENI} />
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
