import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {ReklamacePlynStore} from '../../../../Store/ReklamaceStore';
import {ReklamaceMenuItemModel} from '../../model';
import {REKLAMACE_PLYN_INITIAL_FILTER} from '../../services';
import {ReklamacePlynFilterModel} from '../model';

const initialState = {
    selectedLeftMenuType: 'CEKA_NA_DOPLNENI',
    filter: REKLAMACE_PLYN_INITIAL_FILTER,
} as ReklamacePlynStore;

export const reklamacePlynSlice = createSlice({
    name: 'reklamacePlyn',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setChangeReklamacePlynSelectedLeftMenuType: (state: ReklamacePlynStore, {payload}: PayloadAction<ReklamaceMenuItemModel>): ReklamacePlynStore => ({
            ...state,
            selectedLeftMenuType: payload,
            filter: REKLAMACE_PLYN_INITIAL_FILTER, // Reset filter on menu change because it may contain invalid values
        }),

        setReklamacePlynFilter: (state: ReklamacePlynStore, {payload}: PayloadAction<ReklamacePlynFilterModel>): ReklamacePlynStore => ({
            ...state,
            filter: {...state.filter, ...payload},
        }),

        setClearReklamacePlynFilter: (state: ReklamacePlynStore): ReklamacePlynStore => ({
            ...state,
            filter: REKLAMACE_PLYN_INITIAL_FILTER,
        }),
    },
});

export const {setChangeReklamacePlynSelectedLeftMenuType, setClearReklamacePlynFilter, setReklamacePlynFilter} = reklamacePlynSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const reklamacePlyn = (state: Store) => state.reklamace.plyn;
export const selectReklamaceElektrina = createSelector([reklamacePlyn], (reklamacePlyn) => reklamacePlyn);
