import {useDispatch} from 'react-redux';
import {resetTableStore, setPage, setRowsPerPage} from '../reducers/TableReducer';

type TableActionType = {
    setTablePage: (value: number) => void;
    setTableRowsPerPage: (value: number) => void;
    resetTablePagination: () => void;
};

/**
 * Custom hook pro redux akci společnou napříč aplikací
 *
 * @return {*}  {TableActionType}
 */
export const useTableAction = (): TableActionType => {
    const dispatch = useDispatch();

    return {
        setTablePage: (payload) => {
            dispatch(setPage(payload));
        },
        setTableRowsPerPage: (payload) => {
            dispatch(setRowsPerPage(payload));
        },
        resetTablePagination: () => {
            dispatch(resetTableStore());
        },
    };
};
