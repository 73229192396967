export enum BackendEndpoints {
    DOWNLOAD_CERT = 'downloadCertificate',
    DOWNLOAD_EXTERNAL = 'downloadExternal',
    DOWNLOAD_EXTERNAL_MOBILE = 'generateDownloadLink',
    DOWNLOAD_DMS = 'downloadDms',
    DOWNLOAD_RSODI = 'downloadRsodiPriloha',
    DOWNLOAD_DMS_MOBILE = 'generateDownloadDmsLink',
    DOWNLOAD_RSODI_MOBILE = 'generateDownloadRsodiLink',
    DOWNLOAD = 'download',
    DOWNLOAD_MOBILE = 'generateDownloadLink',
    CERTIFIKAT_LOGIN = 'certifikatLogin',
    LOGIN_CONFIG = 'loginConfig',
    CREATE_RESET_HESLA_POZADAVEK = 'createResetHeslaPozadavek',
    RESET_HESLA_POZADAVEK_INFO = 'resetHeslaPozadavekInfo',
    RESET_HESLA = 'resetHesla',
    REGISTER = 'register',
    REGISTER_MUNICIPALITA = 'registerPravnickaOsoba',
    VERIFY_MUNICIPALITA = 'verifyPravnickaOsoba',
    GRAPHQL = 'graphql',
    CONFIRM_MUNICIPALITA_DS = 'confirmPravnickaOsobaDs',
    CONFIRM_MUNICIPALITA_PRISTUP_DS = 'potvrzeniPristupuPravnickaOsobaDS',
    POTVRZENI_PRISTUPU = 'potvrzeniPristupu',
}
