import {combineReducers} from '@reduxjs/toolkit';
import {zadostiElektrinaSlice} from '../elektrina/reducers/ZadostiElektrinaReducer';
import {zadostiOstatniSlice} from '../ostatni/reducer/ZadostiOstatniReducer';
import {zadostiPlynSlice} from '../plyn/reducers/ZadostiPlynReducer';

export const ZadostiReducer = combineReducers({
    plyn: zadostiPlynSlice.reducer,
    elektrina: zadostiElektrinaSlice.reducer,
    ostatni: zadostiOstatniSlice.reducer,
});
