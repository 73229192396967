import {Maybe, UcetSkupinaTyp, UcetSpravceZmocnenecPristup} from '@eon.cz/apollo13-graphql';
import {AccountBoxOutlined, ExitToAppOutlined, LocationCityOutlined, VerifiedUserOutlined} from '@mui/icons-material';
import {Avatar, Button, Dialog, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {TransitionSlideUp} from '../../Common';
import {LandingBackground} from '../../Common/components/layout/LandingBackground';

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 16,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    dialogContent: {
        padding: 16,
    },
    dialogActions: {
        padding: 16,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        color: theme.palette.secondary.main,
        width: 50,
        height: 50,
    },
    card: {
        marginTop: '10px',
        marginBottom: '10px',
    },
}));
type Props = {
    readonly isActivePristupy: Maybe<UcetSpravceZmocnenecPristup>[];
    readonly handleChooseSkupina: (idPristupu: string | undefined) => () => void;
    readonly handleLogout: () => void;
};

export const ChooseAccess = ({isActivePristupy, handleChooseSkupina, handleLogout}: Props) => {
    const classes = useStyles();
    return (
        <LandingBackground>
            <Dialog open={true} maxWidth="lg" TransitionComponent={TransitionSlideUp}>
                <Paper elevation={8} style={{maxHeight: 900, overflowY: isActivePristupy.length > 6 ? 'auto' : 'hidden', overflowX: 'hidden'}}>
                    <Grid container alignItems="stretch" spacing={1}>
                        <Grid className={classes.card} item xs={12}>
                            <div className={classes.dialogTitle}>
                                <Typography variant="h6">
                                    <FormattedMessage id={Lang.PRISTUP_VYBER} />
                                </Typography>
                            </div>
                            <List>
                                {isActivePristupy.length > 0 &&
                                    isActivePristupy.map((pristup) => {
                                        const getIcon = (typ: UcetSkupinaTyp | undefined) => {
                                            switch (typ) {
                                                case UcetSkupinaTyp.MUNICIPALITA:
                                                    return <LocationCityOutlined />;
                                                case UcetSkupinaTyp.KONCOVY_ZAKAZNIK:
                                                    return <AccountBoxOutlined />;
                                                default:
                                                    return <VerifiedUserOutlined />;
                                            }
                                        };
                                        return (
                                            <ListItem button key={pristup?.id} onClick={handleChooseSkupina(pristup?.id)}>
                                                <ListItemAvatar>
                                                    <Avatar className={classes.avatar}>{getIcon(pristup?.skupina?.typ)}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<Typography variant="body1">{pristup?.skupina?.nazev}</Typography>}
                                                    secondary={<FormattedMessage id={`sprava.uctu.typ.${pristup?.typ.toLowerCase()}`} />}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                {isActivePristupy.length === 0 && (
                                    <>
                                        <div className={classes.dialogContent}>
                                            <Typography variant="body1">
                                                <FormattedMessage id={Lang.PRISTUP_ZADNY_DESCRIPTION} />
                                            </Typography>
                                        </div>
                                        <div className={classes.dialogActions}>
                                            <Button onClick={handleLogout}>
                                                <ExitToAppOutlined />
                                                &nbsp;
                                                <FormattedMessage id={Lang.ODHLASIT_SE} />
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </LandingBackground>
    );
};
