import {SamoodecetDavkaStav} from '@eon.cz/apollo13-graphql';

import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {OdectyElektrinaStore} from '../../../../Store/OdectyStore';
import {ODECTY_STAV_RESENI_VSE, ODECTY_STAV_VSE} from '../../common/constans';
import {OdectyPodaniDavkaModel, SamoodectyMenuItemModel} from '../../common/model';

export const initialState = {
    activeTab: 0,
    davka: {
        id: null,
        cisloDavky: null,
        stav: null,
        samoodecty: undefined,
    } as OdectyPodaniDavkaModel,
    davky: {
        selectedLeftMenuType: 'KONCEPT',
        filter: {
            datumVytvoreniOd: null,
            datumVytvoreniDo: null,
            cisloDavkyFulltext: null,
            datumOdeslaniOd: null,
            datumOdeslaniDo: null,
            vytvoril: null,
        },
        importHromadne: false,
    },
    odecty: {
        selectedLeftMenuType: 'vse',
        filter: {
            datumVytvoreniOd: null,
            cislo: null,
            datumVytvoreniDo: null,
            eanFulltext: null,
            cisloDavkyFulltext: null,
            stav: ODECTY_STAV_VSE,
            stavReseni: ODECTY_STAV_RESENI_VSE,
            cisloPristroje: null,
            duvod: null,
            datumOdeslaniOd: null,
            datumOdeslaniDo: null,
            vytvoril: null,
        },
        importJednotlive: false,
    },
} as OdectyElektrinaStore;

export const odectyElektrinaSlice = createSlice({
    name: 'odectyElektrina',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setOdectyElektrinaDavkaData: (state: OdectyElektrinaStore, {payload}: PayloadAction<OdectyPodaniDavkaModel>): OdectyElektrinaStore => ({
            ...state,
            davka: {
                id: payload.id,
                cisloDavky: payload.cisloDavky,
                stav: payload.stav,
                samoodecty: payload.samoodecty,
            },
        }),
        setResetOdectyElektrinaDavkaData: (state: OdectyElektrinaStore): OdectyElektrinaStore => ({
            ...state,
            davka: initialState.davka,
        }),
        // Elektrina - prehled davek
        setOdectyElektrinaDavkaFilter: (state: OdectyElektrinaStore, {payload}: PayloadAction<any>): OdectyElektrinaStore => ({
            ...state,
            davky: {
                ...state.davky,
                filter: payload,
            },
        }),

        setResetOdectyElektrinaDavkaFilter: (state: OdectyElektrinaStore): OdectyElektrinaStore => ({
            ...state,
            davky: {
                ...state.davky,
                filter: initialState.davky.filter,
            },
        }),

        // Elektrina - prehled odectu
        setOdectyElektrinaOdectyFilter: (state: OdectyElektrinaStore, {payload}: PayloadAction<any>): OdectyElektrinaStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                filter: payload,
            },
        }),

        setResetOdectyElektrinaOdectyFilter: (state: OdectyElektrinaStore): OdectyElektrinaStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                filter: initialState.odecty.filter,
            },
        }),
        setChangeOdectyDavkyElektrinaSelectedLeftMenuType: (
            state: OdectyElektrinaStore,
            {payload}: PayloadAction<SamoodecetDavkaStav>,
        ): OdectyElektrinaStore => ({
            ...state,
            davky: {
                ...state.davky,
                selectedLeftMenuType: payload,
            },
        }),
        setChangeOdectyOdectyElektrinaSelectedLeftMenuType: (
            state: OdectyElektrinaStore,
            {payload}: PayloadAction<SamoodectyMenuItemModel>,
        ): OdectyElektrinaStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                selectedLeftMenuType: payload,
            },
        }),
        setChangeOdectyElektrinaTab: (state: OdectyElektrinaStore, {payload}: PayloadAction<number>): OdectyElektrinaStore => ({
            ...state,
            activeTab: payload,
        }),
        setOdectyElektrinaImportHromadne: (state: OdectyElektrinaStore, {payload}: PayloadAction<boolean>): OdectyElektrinaStore => ({
            ...state,
            davky: {
                ...state.davky,
                importHromadne: payload,
            },
        }),
        setOdectyElektrinaImportJednotlive: (state: OdectyElektrinaStore, {payload}: PayloadAction<boolean>): OdectyElektrinaStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                importJednotlive: payload,
            },
        }),
    },
});

export const {
    setChangeOdectyDavkyElektrinaSelectedLeftMenuType,
    setChangeOdectyOdectyElektrinaSelectedLeftMenuType,
    setChangeOdectyElektrinaTab,
    setOdectyElektrinaDavkaData,
    setOdectyElektrinaDavkaFilter,
    setOdectyElektrinaOdectyFilter,
    setResetOdectyElektrinaDavkaData,
    setResetOdectyElektrinaDavkaFilter,
    setResetOdectyElektrinaOdectyFilter,
    setOdectyElektrinaImportHromadne,
    setOdectyElektrinaImportJednotlive,
} = odectyElektrinaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const odectyElektrina = (state: Store) => state.odecty.elektrina;
export const selectOdectyElektrina = createSelector([odectyElektrina], (odectyElektrina) => odectyElektrina);
