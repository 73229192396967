import {Div} from '@eon.cz/apollo13-frontend-common';
import {styled} from '@mui/material/styles';
import {ReactNode} from 'react';
import {CookiesButton} from '../../../Auth/components/CookiesButton';
import {useScreenOrientation} from '../../services/CommonService';
import {useTablet} from '../../services/PageService';

type DeviceProps = {
    readonly orientation: 'portrait-primary' | 'landscape-primary' | 'landscape-secondary' | 'portrait-secondary';
};

export const getDeviceType = () => {
    const ua = typeof navigator !== 'undefined' ? navigator.userAgent : '';
    const uaNew = typeof navigator !== 'undefined' ? navigator.userAgentData : undefined;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) || uaNew?.mobile) {
        return 'mobile';
    }
    return 'desktop';
};

export const device = ({orientation}: DeviceProps) => {
    const dev = getDeviceType();
    switch (dev) {
        case 'desktop':
            if (orientation === 'portrait-primary') {
                return "url('/static/images/EGD_selfcare_portrait.svg')";
            }
            return "url('/static/images/EGD_selfcare_landscape.svg')";
        case 'mobile':
            if (orientation === 'portrait-primary') {
                return "url('/static/images/EGD_selfcare_mobile.svg')";
            }
            return "url('/static/images/EGD_selfcare_landscape.svg')";
        case 'tablet':
            if (orientation === 'portrait-primary') {
                return "url('/static/images/EGD_selfcare_ipad_portrait.svg')";
            }
            return "url('/static/images/EGD_selfcare_ipad_landscape.svg')";
    }
};

const DivWrapper = styled('div')<{
    backgroundimage: string;
    isTablet: boolean;
    orientation: 'portrait-primary' | 'landscape-primary' | 'landscape-secondary' | 'portrait-secondary';
}>(({theme, backgroundimage, isTablet, orientation}) => ({
    backgroundImage: backgroundimage,
    backgroundSize: isTablet && orientation !== 'portrait-primary' ? '120%' : getDeviceType() === 'mobile' ? '100% auto' : 'auto',
    backgroundColor: '#2C275E',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: isTablet && orientation !== 'portrait-primary' ? 'right bottom' : 'bottom',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(4)} 0`,
}));
const DivChildren = styled('div')(() => ({
    width: 'auto',
    display: 'flex',
    margin: 'auto',
}));

type Props = {
    readonly children: ReactNode;
};

/**
 * Wrapper komponenta obsahující pozadí aplikace
 */
export const LandingBackground = ({children}: Props) => {
    const orientation = useScreenOrientation();
    const isTablet = useTablet();

    const backgroundImage = device({orientation});

    return (
        <DivWrapper backgroundimage={backgroundImage} isTablet={isTablet} orientation={orientation}>
            <DivChildren role="main">{children}</DivChildren>
            <Div
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: getDeviceType() === 'mobile' && orientation === 'landscape-primary' ? 10 : undefined,
                }}
            >
                <CookiesButton />
            </Div>
        </DivWrapper>
    );
};
