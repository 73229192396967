import {ApolloError} from '@apollo/client';
import {PagingSortingGridColumnConfig} from '@eon.cz/apollo13-frontend-common';
import {
    CreateReklamacePlynInput,
    Komodita,
    ReklamaceElektrina,
    ReklamaceElektrinaFilter,
    ReklamaceElektrinaOrderBy,
    ReklamaceElektrinaOrderColumns,
    ReklamaceElektrinaTyp,
    ReklamacePlyn,
    ReklamacePlynDuvod,
    ReklamacePlynFilter,
    ReklamacePlynOrderBy,
    ReklamacePlynOrderColumns,
    ReklamacePlynSmlouva,
    ReklamacePlynTyp,
    ReklamaceStav,
    UpdateReklamacePlynInput,
} from '@eon.cz/apollo13-graphql';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {PrilohyProps} from '../../Common';
import {ReklamaceElektrinaFilterModel, ReklamaceElektrinaFormUpdateInputModel} from '../elektrina/model';
import {REKLAMACE_MENU_ITEM_ALL, ReklamaceMenuItemModel} from '../model';
import {ReklamacePlynFilterModel, ReklamacePlynFormUpdateInputModel} from '../plyn/model';

const PREFIX_FORM_NAME = 'REKLAMACE_';

export const EMAIL = 'podpora.fakturace@egd.cz';
export const REKLAMACE_STAV_VSE = 'vse';
export const REKLAMACE_STAV_RESENI_VSE = 'vse';
export const REKLAMACE_TYP_VSE = 'vse';
export const REKLAMACE_PLYN_INITIAL_FILTER = {
    stav: REKLAMACE_STAV_VSE as 'vse',
    typ: REKLAMACE_TYP_VSE as 'vse',
    stavReseni: REKLAMACE_STAV_RESENI_VSE as 'vse',
    cisloFulltext: null,
    eicFulltext: null,
    datumPodaniFrom: null,
    datumPodaniTo: null,
    vytvoril: null,
    vytvorenoFrom: null,
    vytvorenoTo: null,
} as ReklamacePlynFilterModel;
export const REKLAMACE_ELEKTRINA_INITIAL_FILTER = {
    stav: REKLAMACE_STAV_VSE as 'vse',
    typ: REKLAMACE_TYP_VSE as 'vse',
    stavReseni: REKLAMACE_STAV_RESENI_VSE as 'vse',
    cisloFulltext: null,
    eanFulltext: null,
    datumPodaniFrom: null,
    datumPodaniTo: null,
    vytvoril: null,
    vytvorenoFrom: null,
    vytvorenoTo: null,
} as ReklamaceElektrinaFilterModel;

export const REKLAMACE_MAX_PRILOH = 10;

interface ReklamaceNotificationMessages {
    errorHeadText: React.ReactNode;
    errorTailText: React.ReactNode;
    infoSend: React.ReactNode;
    infoDraft: React.ReactNode;
    reklamace: React.ReactNode;
}

export const ReklamaceMenuMap = {
    0: 'KONCEPT',
    1: 'vse',
    2: 'CEKA_NA_DOPLNENI',
    3: 'STORNO',
    4: 'VYRESENO',
    5: 'V_RESENI',
    6: 'ZAMITNUTO',
} as Record<number, ReklamaceMenuItemModel>;

export const ReklamaceService = {
    FILTER_FORM_NAME: `${PREFIX_FORM_NAME}_FILTER_FORM`,
    CREATE_FORM_NAME: `${PREFIX_FORM_NAME}_CREATE_FORM`,

    UPDATE_FORM_NAME: `${PREFIX_FORM_NAME}_UPDATE_FORM`,

    getAllTypesWithName(komodita: Komodita) {
        const reklamaceTyp = komodita === Komodita.ELEKTRINA ? ReklamaceElektrinaTyp : ReklamacePlynTyp;
        return Object.keys(reklamaceTyp).map((type) => type);
    },

    getElektrinaTypesForCombo: () => {
        return [REKLAMACE_TYP_VSE, ...Object.keys(ReklamaceElektrinaTyp)];
    },

    getPlynTypesForCombo: () => {
        return [REKLAMACE_TYP_VSE, ...Object.keys(ReklamacePlynTyp)];
    },

    getAllStates(): ReklamaceStav[] {
        return Object.values(ReklamaceStav);
    },

    getComboStates(): Array<ReklamaceStav | 'vse'> {
        return [REKLAMACE_STAV_VSE, ...Object.values(ReklamaceStav)];
    },

    getColorByLeftMenuItem(stavType: ReklamaceMenuItemModel) {
        switch (stavType) {
            case REKLAMACE_MENU_ITEM_ALL:
                return 'grey';
            case 'CEKA_NA_DOPLNENI':
                return 'secondary';
            case 'V_RESENI':
                return 'orange';
            case 'KONCEPT':
                return 'primary';
            case 'VYRESENO':
                return 'green';
            case 'ZAMITNUTO':
                return 'error';
            case 'STORNO':
                return 'brown';
            default:
                return 'grey';
        }
    },
    getNameByLeftMenuType(stavType: ReklamaceMenuItemModel) {
        switch (stavType) {
            case REKLAMACE_MENU_ITEM_ALL:
                return Lang.REKLAMACE_LEFT_MENU_VSE;
            case ReklamaceStav.CEKA_NA_DOPLNENI:
                return Lang.REKLAMACE_LEFT_MENU_CEKA_NA_DOPLNENI;
            case ReklamaceStav.V_RESENI:
                return Lang.REKLAMACE_LEFT_MENU_V_RESENI;
            case ReklamaceStav.KONCEPT:
                return Lang.REKLAMACE_LEFT_MENU_KONCEPT;
            case ReklamaceStav.VYRESENO:
                return Lang.REKLAMACE_LEFT_MENU_VYRESENE;
            case ReklamaceStav.ZAMITNUTO:
                return Lang.REKLAMACE_LEFT_MENU_ZAMITNUTO;
            case ReklamaceStav.STORNO:
                return Lang.REKLAMACE_LEFT_MENU_STORNO;
        }
    },
    getAllLeftMenuItems(): ReklamaceMenuItemModel[] {
        return [ReklamaceStav.KONCEPT, REKLAMACE_MENU_ITEM_ALL, ...ReklamaceService.getAllStates().filter((stav) => stav !== ReklamaceStav.KONCEPT)];
    },
    getIconByLeftMenuType(stavType: ReklamaceMenuItemModel) {
        switch (stavType) {
            case REKLAMACE_MENU_ITEM_ALL:
                return 'all_inbox';
            case 'CEKA_NA_DOPLNENI':
                return 'undo';
            case 'V_RESENI':
                return 'donut_large';
            case 'KONCEPT':
                return 'drafts';
            case 'VYRESENO':
                return 'check';
            case 'ZAMITNUTO':
                return 'error_outline';
            case 'STORNO':
                return 'remove_circle_outline';
            default:
                return null;
        }
    },
    isDividerInLeftMenu(type: ReklamaceMenuItemModel): boolean {
        switch (type) {
            case REKLAMACE_MENU_ITEM_ALL:
            case ReklamaceStav.V_RESENI:
            case ReklamaceStav.STORNO:
            case ReklamaceStav.KONCEPT:
                return true;
            default:
                return false;
        }
    },

    getStavByLeftMenuItem(type: ReklamaceMenuItemModel) {
        return type === REKLAMACE_MENU_ITEM_ALL ? undefined : (type.toString().toLocaleUpperCase() as ReklamaceStav);
    },

    getNotificationMessages(): ReklamaceNotificationMessages {
        return {
            errorHeadText: React.createElement(FormattedMessage, {id: Lang.REKLAMACE_NOTIFICATION_ERROR_HEAD_TEXT}),
            errorTailText: React.createElement(FormattedMessage, {id: Lang.REKLAMACE_NOTIFICATION_ERROR_TAIL_TEXT}),
            infoSend: React.createElement(FormattedMessage, {id: Lang.REKLAMACE_NOTIFICATION_INFO_SEND}),
            infoDraft: React.createElement(FormattedMessage, {id: Lang.REKLAMACE_NOTIFICATION_INFO_DRAFT}),
            reklamace: React.createElement(FormattedMessage, {id: Lang.REKLAMACE}),
        };
    },

    /**
     * Check whether filter has any field set (except stav)
     */
    isFilterActiveElektrina: (filter: Omit<ReklamaceElektrinaFilterModel, 'stav'>): boolean => {
        return !isEqual(filter, REKLAMACE_ELEKTRINA_INITIAL_FILTER);
    },

    /**
     * Check whether filter has any field set (except stav)
     */
    isFilterActivePlyn: (filter: Omit<ReklamacePlynFilterModel, 'stav'>): boolean => {
        return !isEqual(filter, REKLAMACE_PLYN_INITIAL_FILTER);
    },

    getStateFilter: (selectedMenuItem: ReklamaceMenuItemModel, filterStav: ReklamaceStav | 'vse' | undefined): ReklamaceStav | undefined => {
        if (selectedMenuItem === REKLAMACE_STAV_VSE) {
            // Use value from filter
            if (filterStav === REKLAMACE_STAV_VSE) {
                // No filter by state
                return undefined;
            } else {
                // Use value
                return filterStav;
            }
        } else {
            // Use menu item
            return ReklamaceService.getStavByLeftMenuItem(selectedMenuItem);
        }
    },

    // mapování hodnot z filtrace na GraphQL volání pro reklamace
    mapToReklamaceElektrinaFiltratioGraphqlModel: (
        selectedMenuItem: ReklamaceMenuItemModel,
        filter: ReklamaceElektrinaFilterModel,
    ): ReklamaceElektrinaFilter => {
        return {
            typ: filter.typ !== REKLAMACE_TYP_VSE ? filter.typ : undefined,
            stav: ReklamaceService.getStateFilter(selectedMenuItem, filter.stav),
            stavReseni: filter.stavReseni !== REKLAMACE_STAV_RESENI_VSE ? filter.stavReseni : null,
            cisloFulltext: filter.cisloFulltext !== '' ? filter.cisloFulltext : undefined,
            eanFulltext: filter.eanFulltext !== '' ? filter.eanFulltext : undefined,
            datumPodaniFrom: filter.datumPodaniFrom !== '' ? filter.datumPodaniFrom : undefined,
            datumPodaniTo: filter.datumPodaniTo !== '' ? filter.datumPodaniTo : undefined,
            vytvorenoFrom: filter.vytvorenoFrom !== '' ? filter.vytvorenoFrom : undefined,
            vytvorenoTo: filter.vytvorenoTo !== '' ? filter.vytvorenoTo : undefined,
            vytvoril: filter.vytvoril ? filter.vytvoril.id : undefined,
        };
    },

    // mapování hodnot z filtrace na GraphQL volání pro reklamace
    mapToReklamacePlynFiltratioGraphqlModel: (selectedMenuItem: ReklamaceMenuItemModel, filter: ReklamacePlynFilterModel): ReklamacePlynFilter => {
        return {
            typ: filter.typ !== REKLAMACE_TYP_VSE ? filter.typ : undefined,
            stav: ReklamaceService.getStateFilter(selectedMenuItem, filter.stav),
            stavReseni: filter.stavReseni !== REKLAMACE_STAV_RESENI_VSE ? filter.stavReseni : null,
            cisloFulltext: filter.cisloFulltext !== '' ? filter.cisloFulltext : undefined,
            eicFulltext: filter.eicFulltext !== '' ? filter.eicFulltext : undefined,
            datumPodaniFrom: filter.datumPodaniFrom !== '' ? filter.datumPodaniFrom : undefined,
            datumPodaniTo: filter.datumPodaniTo !== '' ? filter.datumPodaniTo : undefined,
            vytvorenoFrom: filter.vytvorenoFrom !== '' ? filter.vytvorenoFrom : undefined,
            vytvorenoTo: filter.vytvorenoTo !== '' ? filter.vytvorenoTo : undefined,
            vytvoril: filter.vytvoril ? filter.vytvoril.id : undefined,
        };
    },

    mapReklamaceElektrinaToEdit: (detail: ReklamaceElektrina): ReklamaceElektrinaFormUpdateInputModel => ({
        id: detail.id,
        ean: detail.ean,
        typ: ReklamaceElektrinaTyp[detail.typ],
        duvod: detail.duvod.id,
        email: !!detail.email ? detail.email : '',
        telefon: !!detail.telefon ? detail.telefon : '',
        smlouva: detail.smlouva.id,
        aktualniStavKDatu: !!detail.aktualniStavKDatu ? detail.aktualniStavKDatu : undefined,
        aktualniStavMeridlaNt: !!detail.aktualniStavMeridlaNt ? detail.aktualniStavMeridlaNt : undefined,
        pozadovanyStavKDatu: !!detail.pozadovanyStavKDatu ? detail.pozadovanyStavKDatu : undefined,
        pozadovanyStavMeridlaNt: !!detail.pozadovanyStavMeridlaNt ? detail.pozadovanyStavMeridlaNt : undefined,
        aktualniStavMeridlaVt: !!detail.aktualniStavMeridlaVt ? detail.aktualniStavMeridlaVt : undefined,
        pozadovanyStavMeridlaVt: !!detail.pozadovanyStavMeridlaVt ? detail.pozadovanyStavMeridlaVt : undefined,
        cisloPristroje: !!detail.pristroj && !!detail.pristroj.cislo ? detail.pristroj.cislo : undefined,
        zadostOPrezkouseni: detail.zadostOPrezkouseni,
        popis: !!detail.popis ? detail.popis : '',
    }),

    mapReklamacePlynToEdit: (detail: ReklamacePlyn): ReklamacePlynFormUpdateInputModel => ({
        id: detail.id,
        eic: detail.eic,
        typ: ReklamacePlynTyp[detail.typ],
        duvod: detail.duvod.id,
        email: !!detail.email ? detail.email : '',
        telefon: !!detail.telefon ? detail.telefon : '',
        smlouva: detail.smlouva.id,
        aktualniStavKDatu: !!detail.aktualniStavKDatu ? detail.aktualniStavKDatu : undefined,
        aktualniStavMeridla: !!detail.aktualniStavMeridla ? detail.aktualniStavMeridla : undefined,
        pozadovanyStavKDatu: !!detail.pozadovanyStavKDatu ? detail.pozadovanyStavKDatu : undefined,
        pozadovanyStavMeridla: !!detail.pozadovanyStavMeridla ? detail.pozadovanyStavMeridla : undefined,
        cisloPristroje: !!detail.pristroj && !!detail.pristroj.cislo ? detail.pristroj.cislo : undefined,
        zadostOPrezkouseni: detail.zadostOPrezkouseni,
        popis: !!detail.popis ? detail.popis : '',
    }),

    isStornoEnabled: (reklamace: ReklamaceElektrina | ReklamacePlyn) =>
        (reklamace.stav === ReklamaceStav.V_RESENI || reklamace.stav === ReklamaceStav.CEKA_NA_DOPLNENI) && !reklamace.cekaNaStorno && reklamace.zvalidovano,

    getStavyReseniColorTypeReklamace() {
        return {
            CEKA_NA_ODESLANI: 'neutral',
            ODESLANO: 'inprogress',
            NEUSPESNE_PODANI: 'negative',
            CEKA_NA_DOPLNENI: 'inprogress',
            DOPLNENI_ODESLANO: 'inprogress',
            NEOPRAVNENA: 'negative',
            OPRAVNENA: 'positive',
            ZMENA_TERMINU: 'inprogress',
            ODESLAN_POZADAVEK_NA_STORNO: 'inprogress',
            STORNOVANO: 'negative',
        };
    },
};

export interface ReklamacePlynProps extends Partial<PrilohyProps> {
    readonly formValues?: {eic: string; typ: ReklamacePlynTyp; smlouva: string};
    readonly editMode?: boolean;
    readonly smlouvy: ReklamacePlynSmlouva[];
    readonly duvody: ReklamacePlynDuvod[];
    readonly loading: boolean;
    readonly errorSmlouvy: ApolloError | undefined;
    readonly initialValues?: Partial<CreateReklamacePlynInput> | UpdateReklamacePlynInput;
}

export const COLUMN_CONFIG_REKLAMACE = (
    komodita: Komodita,
    menuItem: ReklamaceMenuItemModel,
): Array<PagingSortingGridColumnConfig<ReklamaceElektrinaOrderColumns | ReklamacePlynOrderColumns, ReklamaceElektrinaOrderBy | ReklamacePlynOrderBy>> => {
    const res: Array<
        PagingSortingGridColumnConfig<ReklamaceElektrinaOrderColumns | ReklamacePlynOrderColumns, ReklamaceElektrinaOrderBy | ReklamacePlynOrderBy>
    > = [];
    res.push({columnHeader: ''});

    if (komodita === Komodita.ELEKTRINA) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_CISLO} />, orderByColumnName: ReklamaceElektrinaOrderColumns.CISLO});
        res.push({columnHeader: <FormattedMessage id={Lang.EAN} />, orderByColumnName: ReklamaceElektrinaOrderColumns.EAN});
    }
    if (komodita === Komodita.PLYN) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_CISLO} />, orderByColumnName: ReklamacePlynOrderColumns.CISLO});
        res.push({columnHeader: <FormattedMessage id={Lang.EIC} />, orderByColumnName: ReklamacePlynOrderColumns.EIC});
    }
    res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_TYP} />});
    if (menuItem === REKLAMACE_MENU_ITEM_ALL) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_STAV} />});
    }
    res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_STAV_RESENI} />});
    if (menuItem === ReklamaceStav.KONCEPT) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_VYTVORENO} />});
    } else {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_DATUM_PODANI} />});
    }
    res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_VYTVORIL} />});
    res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_ZAKAZNIK} />});
    if (menuItem === ReklamaceStav.ZAMITNUTO) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_DUVOD_ZAMITNUTI} />});
    }
    if (menuItem === ReklamaceStav.STORNO) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_DUVOD_STORNA} />});
    }
    if (menuItem === ReklamaceStav.KONCEPT) {
        res.push({columnHeader: <FormattedMessage id={Lang.REKLAMACE_CHYBA} />});
    }
    res.push({columnHeader: ''});

    return res;
};
