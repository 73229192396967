import {BackendEndpoints} from '../../../../server/BackendEndpoints';

export interface PageRouteModel {
    /**
     * Path for the route
     */
    readonly path: string;

    /**
     * Prepend commodity before the route
     */
    readonly prependCommodity?: boolean;

    /**
     * When prependCommodity is set and no commodity is passed, getPathname results in error.
     * Unless this flag is set - in that case, no error is thrown and commodity is not prepared when it is missing.
     */
    readonly commodityCanBeMissing?: boolean;
}

export const PageRoute = {
    LOGIN: {path: '/login', prependCommodity: false},
    NASTENKA: {path: '/', prependCommodity: true, commodityCanBeMissing: true},
    SPRAVA_UCTU: {path: '/spravaUctu'},
    ODECTY: {path: '/odecty', prependCommodity: true},
    KONTAKTY: {path: '/kontakty'},
    SMLOUVY_ODBERNYCH_MIST: {path: '/odbernaMista', prependCommodity: true},
    ZADOSTI: {path: '/zadosti', prependCommodity: true},
    ELEKTRIKARI: {path: '/elektrikari', prependCommodity: true},
    ELEKTRIKARI_ZADATEL: {path: '/elektrikari', prependCommodity: false},
    ZADOSTI_NE_OBCHODNIK: {path: '/zadosti', prependCommodity: false},
    REVIZNI_ZPRAVY: {path: '/revizniZpravy', prependCommodity: true},
    DODATKY: {path: '/dodatky', prependCommodity: true},
    PRILOHY_KE_STAZENI: {path: '/prilohyKeStazeni'},
    REKLAMACE: {path: '/reklamace', prependCommodity: true},
    POZADAVKY: {path: '/pozadavky'},
    HISTORIE_SPOTREB_DODAVKY: {path: '/historieSpotrebDodavky', prependCommodity: true},
    DOWNLOAD: {path: '/download'},
    PRILOHY_DOWNLOAD: {path: `/api/${BackendEndpoints.DOWNLOAD}`},
    NASTAVENI_HESLA: {path: '/nastaveniHesla'},
    MOBILE: {path: '/mobile'},
    BEZPROUDI: {path: '/bezproudi', prependCommodity: true},
    PLANOVANE_SERVISNI_PRACE: {path: '/planovaneServisniPrace', prependCommodity: false},
    INVESTICNI_STAVBY: {path: '/investicniStavby'},
    ESD: {path: '/energetickaSpolecentsvi', prependCommodity: false},
    RSODI: {path: '/zakladniSmlouvyDistributorem', prependCommodity: true},
};
