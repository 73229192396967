import {pagingSortingGridSlice, PagingSortingGridState} from '@eon.cz/apollo13-frontend-common/lib/components/grid/PagingSortingGridReducer';
import {notificationsSlice, NotificationsStore} from '@eon.cz/apollo13-frontend-common/lib/notification/reducer/NotificationsReducer';
import {combineReducers, configureStore, PayloadAction} from '@reduxjs/toolkit';
import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import {PersistConfig} from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage/session';
import {authSlice} from '../modules/Auth';
import {bezproudiSlice} from '../modules/Bezproudi/reducers/BezproudiReducer';
import {BezproudiStore} from '../modules/Bezproudi/store';
import {commonSlice} from '../modules/Common/reducers/CommonReducers';
import {tableSlice} from '../modules/Common/reducers/TableReducer';
import {CommonStore} from '../modules/Common/store/CommonStore';
import {TableStore} from '../modules/Common/store/TableStore';
import {dodatkySlice} from '../modules/Dodatky/reducers/DodatkyReducer';
import {messageSlice} from '../modules/Message';
import {odbernaMistaSlice} from '../modules/OdbernaMista';
import {omHistorieSpotrebSlice} from '../modules/OdbernaMista/reducers/OMHistorieSpotrebReducer';
import {OdectyReducer} from '../modules/Odecty/reducers/OdectyReducer';
import {prilohyKeStazeniSlice} from '../modules/PrilohyKeStazeni';
import {registrationSlice} from '../modules/Registration/reducers/RegistrationReducer';
import {ReklamaceReducer} from '../modules/Reklamace';
import {revizniZpravySlice} from '../modules/RevizniZpravy';
import {spravaUctuSlice} from '../modules/SpravaUctu';
import {ZadostiReducer} from '../modules/Zadosti/reducers/ZadostiReducer';
import {RootActionType} from '../RootAction';
import {Store} from '../Store';
import {AuthStore} from '../Store/AuthStore';
import {DodatkyStore} from '../Store/DodatkyStore';
import {MessageStore} from '../Store/MessageStore';
import {OdbernaMistaStore} from '../Store/OdbernaMistaStore';
import {OdectyStore} from '../Store/OdectyStore';
import {OMHistorieSpotrebStore} from '../Store/OMHistorieSpotrebStore';
import {PrilohyKeStazeniStore} from '../Store/PrilohyKeStazeniStore';
import {RegistrationStore} from '../Store/RegistrationStore';
import {ReklamaceStore} from '../Store/ReklamaceStore';
import {RevizniZpravyStore} from '../Store/RevizniZpravyStore';
import {SpravaUctuStore} from '../Store/SpravaUctuStore';
import {ZadostiStore} from '../Store/ZadostiStore';

const persistConfig: PersistConfig<Store> = {
    key: 'primary-d24',
    storage,
    version: 1,
    whitelist: ['common'], // place to select which state you want to persist
};

export type RootState = {
    /* A type definition for the state of the notifications. */
    common: CommonStore;
    bezproudi: BezproudiStore;
    auth: AuthStore;
    message: MessageStore;
    dodatky: DodatkyStore;
    odbernaMista: OdbernaMistaStore;
    omHistorieSpotreb: OMHistorieSpotrebStore;
    odecty: OdectyStore;
    reklamace: ReklamaceStore;
    prilohyKeStazeni: PrilohyKeStazeniStore;
    registration: RegistrationStore;
    revizniZpravy: RevizniZpravyStore;
    spravaUctu: SpravaUctuStore;
    pagingSortingGrid: PagingSortingGridState;
    notifications: NotificationsStore;
    zadosti: ZadostiStore;
    table: TableStore;
};

const rootReducer = (store: RootState | undefined, action: PayloadAction<any>) => {
    // combine all reducers
    const combined = combineReducers({
        common: commonSlice.reducer,
        bezproudi: bezproudiSlice.reducer,
        auth: authSlice.reducer,
        message: messageSlice.reducer,
        dodatky: dodatkySlice.reducer,
        odbernaMista: odbernaMistaSlice.reducer,
        omHistorieSpotreb: omHistorieSpotrebSlice.reducer,
        odecty: OdectyReducer,
        reklamace: ReklamaceReducer,
        zadosti: ZadostiReducer,
        prilohyKeStazeni: prilohyKeStazeniSlice.reducer,
        registration: registrationSlice.reducer,
        revizniZpravy: revizniZpravySlice.reducer,
        spravaUctu: spravaUctuSlice.reducer,
        pagingSortingGrid: pagingSortingGridSlice.reducer,
        notifications: notificationsSlice.reducer,
        table: tableSlice.reducer,
    });

    if (action.type === RootActionType.LOGOUT) {
        // Reset store with passing state of undefined
        return combined(undefined, action);
    }

    // Go normal
    return combined(store, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const makeStore = () => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'notifications/addNotification'],
                    ignoreActionPaths: ['notifications.notifications'],
                    isSerializable: () => true,
                },
            }),
    });
};

const store = makeStore();

export default store;
