import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig();
/**
 * Kontroluje, zda žádaná featura portálu je povolena nebo ne
 */
export const FeatureUtils = {
    isFeatureEnabled(featureEnvKey: string): boolean {
        // checkuje jestli featureEnvKey není undefined, a zároveň jestli se rovná 'true', pak vrací true, jinak false
        return !!publicRuntimeConfig[featureEnvKey] && publicRuntimeConfig[featureEnvKey] === 'true';
    },
};
