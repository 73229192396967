import {PageRouteModel} from '../model';
import {getPathname} from './RouteService';

// Povolené formáty příloh
const FileMimeTypeExtensionMap = [
    {ext: 'doc', mime: 'application/msword'},
    {ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
    {ext: 'xls', mime: 'application/vnd.ms-excel'},
    {ext: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
    {ext: 'pdf', mime: 'application/pdf'},
    {ext: 'csv', mime: 'text/csv'},
    {ext: 'xml', mime: 'application/xml'},
    {ext: 'xml', mime: 'text/xml'},
    {ext: 'jpg', mime: 'image/jpeg'},
    {ext: 'jpeg', mime: 'image/jpeg'},
    {ext: 'gif', mime: 'image/gif'},
    {ext: 'png', mime: 'image/png'},
    {ext: 'text', mime: 'text/plain'},
    {ext: 'msg', mime: 'application/vnd.ms-outlook'},
];

export const isNullOrUndefined = (val: unknown) => val === null || val === undefined;

export const isNullOrUndefinedOrEmpty = (val: unknown) => isNullOrUndefined(val) || val === '';

export const isNotNullOrUndefinedOrEmpty = (val: unknown) => !isNullOrUndefinedOrEmpty(val);

/**
 * Získá extension přímo z názvu souboru.
 */
export const getFilenameExtension = (filename: string | undefined | null) => {
    if (!isNullOrUndefinedOrEmpty(filename)) {
        const splitted = filename?.split('.');
        if (splitted && splitted?.length > 1) {
            return splitted?.[splitted.length - 1];
        }
    }
    return null;
};

const FileAllowedTypeImport = {XLS: 'xls', XLSX: 'xlsx'} as const;

export const MAX_FILE_SIZE = 5_000_000;
export const MIN_FILE_SIZE = 1;

export const FileService = {
    getBase64: (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = () => {
                const data: string | ArrayBuffer | null = reader.result;
                const codesArray = new Uint8Array(data as ArrayBuffer);
                const arr: string[] = new Array(codesArray.length);
                codesArray.forEach((code, n) => {
                    arr[n] = String.fromCharCode(code);
                });
                const base64 = btoa(arr.join(''));
                resolve(base64);
            };
            reader.onerror = (error) => reject(error);
        });
    },

    /**
     * Získá extension podle mime type, případně podle filename extension.
     */
    getFileExtension: (file: File) => {
        if (isNotNullOrUndefinedOrEmpty(file.type)) {
            return FileService.getFileExtensionByMimeType(file.type);
        }
        return FileService.getFilenameExtension(file.name);
    },

    getAllowedExtensions: (files: File[]): boolean =>
        files.filter(
            (file) =>
                FileService.getFilenameExtension(file.name) === FileAllowedTypeImport.XLS ||
                FileService.getFilenameExtension(file.name) === FileAllowedTypeImport.XLSX,
        ).length > 0,

    /**
     * Získá extension přímo z názvu souboru.
     */
    getFilenameExtension: (filename: string) => getFilenameExtension(filename),

    /**
     * Získa extension podle mime type z mapy. Pokud nenajde protihodnotu, vrací mime zpět.
     */
    getFileExtensionByMimeType: (mime: string) => {
        const extensionMimePair = FileMimeTypeExtensionMap.find((ext) => ext.mime === mime);
        return extensionMimePair?.ext ? extensionMimePair.ext : mime;
    },
};

/**
 * Get path name from route and postfix, and convert empty string to slash (= go to root) since empty
 * string does not work that way.
 * @param {PageRoute} route - PageRoute
 * @param {string} [postfix] - string = ''
 * @returns A function that takes in a route and a postfix and returns a pathname.
 */
export const getParsePathname = (route: PageRouteModel, postfix?: string) => {
    // Get path name
    const pathName = getPathname(route, postfix);

    // Convert empty string to slash (= go to root) since empty string does not work that way
    return pathName === '' ? '/' : pathName;
};
