import {OdberneMistoElektrinaHistorieSpotrebArgs, OdberneMistoPlynHistorieSpotrebArgs} from '@eon.cz/apollo13-graphql';

import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {OMHistorieSpotrebStore} from '../../../Store/OMHistorieSpotrebStore';

export const initialState = {
    queryArgsEle: {},
    queryArgsPlyn: {},
} as OMHistorieSpotrebStore;

export const omHistorieSpotrebSlice = createSlice({
    name: 'omHistorieSpotreb',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setHistorieSpotrebElektrinaFilter: (
            state: OMHistorieSpotrebStore,
            {payload}: PayloadAction<OdberneMistoElektrinaHistorieSpotrebArgs>,
        ): OMHistorieSpotrebStore => ({
            ...state,
            queryArgsEle: {...state.queryArgsEle, ...payload},
        }),
        setHistorieSpotrebPlynFilter: (
            state: OMHistorieSpotrebStore,
            {payload}: PayloadAction<OdberneMistoPlynHistorieSpotrebArgs>,
        ): OMHistorieSpotrebStore => ({
            ...state,
            queryArgsPlyn: {...state.queryArgsPlyn, ...payload},
        }),
        setResetHistorieSpotrebElektrinaFilter: (state: OMHistorieSpotrebStore): OMHistorieSpotrebStore => ({
            ...state,
            queryArgsEle: {...initialState.queryArgsEle},
        }),
        setResetHistorieSpotrebPlynFilter: (state: OMHistorieSpotrebStore): OMHistorieSpotrebStore => ({
            ...state,
            queryArgsPlyn: {...initialState.queryArgsPlyn},
        }),
    },
});

export const {setHistorieSpotrebElektrinaFilter, setHistorieSpotrebPlynFilter, setResetHistorieSpotrebElektrinaFilter, setResetHistorieSpotrebPlynFilter} =
    omHistorieSpotrebSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const omHistorieSpotreb = (state: Store) => state.omHistorieSpotreb;
export const selectOmHistorieSpotreb = createSelector([omHistorieSpotreb], (omHistorieSpotreb) => omHistorieSpotreb);
