import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {RevizniZpravyStore} from '../../../Store/RevizniZpravyStore';
import {RevizniZpravyFilterModel, RevizniZpravyMenuItemModel} from '../model';
import {REVIZNI_ZPRAVY_NAPETOVA_UROVEN_VSE, REVIZNI_ZPRAVY_STAV_RESENI_VSE, REVIZNI_ZPRAVY_VSE} from '../services/RevizniZpravyService';

export const initialState = {
    selectedLeftMenuType: 'KONCEPT',
    filter: {
        stavReseni: REVIZNI_ZPRAVY_STAV_RESENI_VSE,
        cisloFulltext: null,
        eanFulltext: null,
        stav: REVIZNI_ZPRAVY_VSE,
        datumVytvoreniOd: null,
        datumVytvoreniDo: null,
        datumOdeslaniOd: null,
        datumOdeslaniDo: null,
        napetovaUroven: REVIZNI_ZPRAVY_NAPETOVA_UROVEN_VSE,
        vytvoril: null,
    },
} as RevizniZpravyStore;

export const revizniZpravySlice = createSlice({
    name: 'revizniZpravy',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setRevizniZpravyFilter: (state: RevizniZpravyStore, {payload}: PayloadAction<RevizniZpravyFilterModel>): RevizniZpravyStore => ({
            ...state,
            filter: {...state.filter, ...payload},
        }),

        setClearRevizniZpravyFilter: (state: RevizniZpravyStore): RevizniZpravyStore => ({
            ...state,
            filter: initialState.filter,
        }),
        setChangeRevizniZpravySelecetdLeftMenuType: (state: RevizniZpravyStore, {payload}: PayloadAction<RevizniZpravyMenuItemModel>): RevizniZpravyStore => ({
            ...state,
            selectedLeftMenuType: payload,
        }),
    },
});

export const {setChangeRevizniZpravySelecetdLeftMenuType, setClearRevizniZpravyFilter, setRevizniZpravyFilter} = revizniZpravySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const revizniZpravy = (state: Store) => state.revizniZpravy;
export const selectRevizniZpravy = createSelector([revizniZpravy], (revizniZpravy) => revizniZpravy);
