import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig();

export const GA_TRACKING_ID = publicRuntimeConfig.NEXT_PUBLIC_GA_ID;
export const EON_CONVERSE_ID = publicRuntimeConfig.NEXT_PUBLIC_GA_CONVERSE_ID;
export const USER_CENTRICS_SETTINGS_ID = publicRuntimeConfig.NEXT_PUBLIC_USERCENTRICS_SETTINGS_ID;
export const NEXT_PUBLIC_GTM_ANDROID_ID = publicRuntimeConfig.NEXT_PUBLIC_GTM_ANDROID_ID;
export const NEXT_PUBLIC_GTM_IOS_ID = publicRuntimeConfig.NEXT_PUBLIC_GTM_IOS_ID;
export const NEXT_PUBLIC_GTM_WEB_ID = publicRuntimeConfig.NEXT_PUBLIC_GTM_WEB_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
    if (typeof window.gtag !== 'undefined') {
        window.gtag('config', GA_TRACKING_ID, {
            page_path: url,
        });
    }
};

type GTagEvent = {
    action: string;
    category?: string;
    label?: string;
    value?: number;
    send_to?: string;
    allow_custom_scripts?: boolean;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({action, category, label, value, send_to, allow_custom_scripts}: GTagEvent) => {
    if (typeof window.gtag !== 'undefined') {
        window?.gtag('event', action, {
            event_category: category,
            event_label: label,
            value,
            send_to,
            allow_custom_scripts,
        });
    }
};
