import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {ReklamaceElektrinaStore} from '../../../../Store/ReklamaceStore';
import {ReklamaceMenuItemModel} from '../../model';
import {REKLAMACE_ELEKTRINA_INITIAL_FILTER} from '../../services';
import {ReklamaceElektrinaFilterModel} from '../model';

const initialState = {
    selectedLeftMenuType: 'CEKA_NA_DOPLNENI',
    filter: REKLAMACE_ELEKTRINA_INITIAL_FILTER,
} as ReklamaceElektrinaStore;

export const reklamaceElektrinaSlice = createSlice({
    name: 'reklamaceElektrina',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setChangeReklamaceElektrinaSelectedLeftMenuType: (
            state: ReklamaceElektrinaStore,
            {payload}: PayloadAction<ReklamaceMenuItemModel>,
        ): ReklamaceElektrinaStore => ({
            ...state,
            selectedLeftMenuType: payload,
            filter: REKLAMACE_ELEKTRINA_INITIAL_FILTER, // Reset filter on menu change because it may contain invalid values
        }),

        setReklamaceElektrinaFilter: (state: ReklamaceElektrinaStore, {payload}: PayloadAction<ReklamaceElektrinaFilterModel>): ReklamaceElektrinaStore => ({
            ...state,
            filter: {...state.filter, ...payload},
        }),

        setClearReklamaceElektrinaFilter: (state: ReklamaceElektrinaStore): ReklamaceElektrinaStore => ({
            ...state,
            filter: REKLAMACE_ELEKTRINA_INITIAL_FILTER,
        }),
    },
});

export const {setChangeReklamaceElektrinaSelectedLeftMenuType, setClearReklamaceElektrinaFilter, setReklamaceElektrinaFilter} = reklamaceElektrinaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const reklamaceElektrina = (state: Store) => state.reklamace.elektrina;
export const selectReklamaceElektrina = createSelector([reklamaceElektrina], (reklamaceElektrina) => reklamaceElektrina);
