import {SafeFormattedMessage} from '@eon.cz/apollo13-frontend-common';
import {LoadingDialog} from '@eon.cz/apollo13-frontend-common/lib/components/dialog/LoadingDialog';
import {Komodita} from '@eon.cz/apollo13-graphql';
import {ExitToAppOutlined} from '@mui/icons-material';
import {Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Paper, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {LandingBackground} from '../../Common/components/layout/LandingBackground';
import {KomoditaIcon} from '../../Komodita';

type Props = {
    readonly handleLogout: () => void;
    readonly handleOnChooseKomodita: (komodita: Komodita) => () => void;
    readonly zadnaKomoditaKVyberu: boolean;
    readonly haveData: boolean;
    readonly komodityList: Komodita[];
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 16,
    },
    dialogContent: {
        padding: 16,
    },
    dialogActions: {
        padding: 16,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const ChooseKomodita = ({handleLogout, handleOnChooseKomodita, zadnaKomoditaKVyberu, haveData, komodityList}: Props) => {
    const classes = useStyles();
    return (
        <LandingBackground>
            <LoadingDialog open={!haveData} />
            {haveData && (
                <Paper elevation={8}>
                    <div className={classes.dialogTitle}>
                        <Typography variant="h6">
                            <FormattedMessage id={zadnaKomoditaKVyberu ? Lang.KOMODITA_ZADNA_TITLE : Lang.KOMODITA_VYBER} />
                        </Typography>
                    </div>
                    {!zadnaKomoditaKVyberu && (
                        <List>
                            {komodityList.map((row) => (
                                <ListItem button onClick={handleOnChooseKomodita(row)} key={row}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <KomoditaIcon type={row} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={<SafeFormattedMessage langArray={Lang} langArrayKey={`KOMODITA_${row}`} fallbackMessage={row} />} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                    {zadnaKomoditaKVyberu && (
                        <>
                            <div className={classes.dialogContent}>
                                <Typography variant="body1">
                                    <FormattedMessage id={Lang.KOMODITA_ZADNA_DESCRIPTION} />
                                </Typography>
                            </div>
                            <div className={classes.dialogActions}>
                                <Button onClick={handleLogout}>
                                    <ExitToAppOutlined />
                                    &nbsp;
                                    <FormattedMessage id={Lang.ODHLASIT_SE} />
                                </Button>
                            </div>
                        </>
                    )}
                </Paper>
            )}
        </LandingBackground>
    );
};
