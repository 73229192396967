import {ZadostDavkaStav} from '@eon.cz/apollo13-graphql';

import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {ZadostiPlynStore} from '../../../../Store/ZadostiStore';
import {ZADOSTI_DUVOD_VSE, ZADOSTI_STAV_RESENI_VSE, ZADOSTI_STAV_VSE, ZADOSTI_TYP_PRIPOJENI_VSE, ZADOSTI_TYP_VSE} from '../../common/constants';
import {ZadostiMenuItemModel} from '../../model';

export const initialStatePlyn = {
    activeTab: 0,
    openImportOne: false,
    openImportTwo: false,
    rezervaceDistribucniKapacity: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                cisloDavkyFulltext: null,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                vytvoril: null,
            },
        },
        zadosti: {
            selectedLeftMenuType: 'CEKA_NA_OPRAVU',
            filter: {
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eicFulltext: null,
                stav: ZADOSTI_STAV_VSE,
                typ: ZADOSTI_TYP_VSE,
                podaniOpravyAktivni: true,
                typZmenyDodavatele: ZADOSTI_TYP_VSE,
                duvod: ZADOSTI_DUVOD_VSE,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                terminZahajeniDistribuceOd: null,
                terminZahajeniDistribuceDo: null,
                terminUkonceniDistribuceOd: null,
                terminUkonceniDistribuceDo: null,
                vytvoril: null,
            },
        },
    },
    ukonceniPreruseni: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                cisloDavkyFulltext: null,
                datumOdeslaniDo: null,
                datumOdeslaniOd: null,
                datumVytvoreniDo: null,
                datumVytvoreniOd: null,
                vytvoril: null,
            },
        },
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stav: ZADOSTI_STAV_VSE,
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eicFulltext: null,
                vytvoril: null,
                typ: ZADOSTI_TYP_VSE,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                pozadovanyTerminOd: null,
                pozadovanyTerminDo: null,
            },
        },
    },
    obnoveni: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                cisloDavkyFulltext: null,
                datumOdeslaniDo: null,
                datumOdeslaniOd: null,
                datumVytvoreniDo: null,
                datumVytvoreniOd: null,
                vytvoril: null,
            },
        },
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stav: ZADOSTI_STAV_VSE,
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eicFulltext: null,
                vytvoril: null,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                pozadovanyTerminOd: null,
                pozadovanyTerminDo: null,
            },
        },
    },
    sopPrepis: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                cisloDavkyFulltext: null,
                datumOdeslaniDo: null,
                datumOdeslaniOd: null,
                datumVytvoreniDo: null,
                datumVytvoreniOd: null,
                vytvoril: null,
            },
        },
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stav: ZADOSTI_STAV_VSE,
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eicFulltext: null,
                vytvoril: null,
                typPripojeni: ZADOSTI_TYP_PRIPOJENI_VSE,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                zahajeniOdberuOd: null,
                zahajeniOdberuDo: null,
            },
        },
    },
} as ZadostiPlynStore;

export const zadostiPlynSlice = createSlice({
    name: 'zadostiPlyn',
    initialState: initialStatePlyn,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setZadostiPlynChangeTab: (state: ZadostiPlynStore, {payload}: PayloadAction<number>): ZadostiPlynStore => ({
            ...state,
            activeTab: payload,
        }),

        // Rezervace distribuční kapacity
        setZadostiPlynChangeRDKSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostDavkaStav>): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                davky: {
                    ...state.rezervaceDistribucniKapacity.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeRDKZadostiSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostiMenuItemModel>): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                zadosti: {
                    ...state.rezervaceDistribucniKapacity.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeRDKTab: (state: ZadostiPlynStore, {payload}: PayloadAction<number>): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                activeTab: payload,
            },
        }),
        setZadostiPlynRDKDavkyFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                davky: {
                    ...state.rezervaceDistribucniKapacity.davky,
                    filter: {...payload},
                },
            },
        }),
        setResetZadostiPlynRDKDavkyFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                davky: {
                    ...state.rezervaceDistribucniKapacity.davky,
                    filter: initialStatePlyn.rezervaceDistribucniKapacity.davky.filter,
                },
            },
        }),
        setZadostiPlynRDKZadostiFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                zadosti: {
                    ...state.rezervaceDistribucniKapacity.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynRDKZadostiFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            rezervaceDistribucniKapacity: {
                ...state.rezervaceDistribucniKapacity,
                zadosti: {
                    ...state.rezervaceDistribucniKapacity.zadosti,
                    filter: initialStatePlyn.rezervaceDistribucniKapacity.zadosti.filter,
                },
            },
        }),
        // Ukončení/Přerušení
        setZadostiPlynChangeUPSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostDavkaStav>): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                davky: {
                    ...state.ukonceniPreruseni.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeUPZadostiSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostiMenuItemModel>): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                zadosti: {
                    ...state.ukonceniPreruseni.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeUPTab: (state: ZadostiPlynStore, {payload}: PayloadAction<number>): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                activeTab: payload,
            },
        }),
        setZadostiPlynUPDavkyFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                davky: {
                    ...state.ukonceniPreruseni.davky,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynUPDavkyFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                davky: {
                    ...state.ukonceniPreruseni.davky,
                    filter: initialStatePlyn.ukonceniPreruseni.davky.filter,
                },
            },
        }),
        setZadostiPlynUPZadostiFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                zadosti: {
                    ...state.ukonceniPreruseni.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynUPZadostiFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            ukonceniPreruseni: {
                ...state.ukonceniPreruseni,
                zadosti: {
                    ...state.ukonceniPreruseni.zadosti,
                    filter: initialStatePlyn.ukonceniPreruseni.zadosti.filter,
                },
            },
        }),
        // Obnoveni
        setZadostiPlynChangeOBNSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostDavkaStav>): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                davky: {
                    ...state.obnoveni.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeOBNZadostiSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostiMenuItemModel>): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                zadosti: {
                    ...state.obnoveni.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeOBNTab: (state: ZadostiPlynStore, {payload}: PayloadAction<number>): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                activeTab: payload,
            },
        }),
        setZadostiPlynOBNDavkyFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                davky: {
                    ...state.obnoveni.davky,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynOBNDavkyFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                davky: {
                    ...state.obnoveni.davky,
                    filter: initialStatePlyn.obnoveni.davky.filter,
                },
            },
        }),
        setZadostiPlynOBNZadostiFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                zadosti: {
                    ...state.obnoveni.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynOBNZadostiFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            obnoveni: {
                ...state.obnoveni,
                zadosti: {
                    ...state.obnoveni.zadosti,
                    filter: initialStatePlyn.obnoveni.zadosti.filter,
                },
            },
        }),

        // SOP
        setZadostiPlynChangeSOPSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostDavkaStav>): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                davky: {
                    ...state.sopPrepis.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeSOPZadostiSelectedLeftMenuType: (state: ZadostiPlynStore, {payload}: PayloadAction<ZadostiMenuItemModel>): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                zadosti: {
                    ...state.sopPrepis.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiPlynChangeSOPTab: (state: ZadostiPlynStore, {payload}: PayloadAction<number>): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                activeTab: payload,
            },
        }),
        setZadostiPlynSOPDavkyFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                davky: {
                    ...state.sopPrepis.davky,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynSOPDavkyFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                davky: {
                    ...state.sopPrepis.davky,
                    filter: initialStatePlyn.sopPrepis.davky.filter,
                },
            },
        }),
        setZadostiPlynSOPZadostiFilter: (state: ZadostiPlynStore, {payload}: PayloadAction<any>): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                zadosti: {
                    ...state.sopPrepis.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiPlynSOPZadostiFilter: (state: ZadostiPlynStore): ZadostiPlynStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                zadosti: {
                    ...state.sopPrepis.zadosti,
                    filter: initialStatePlyn.sopPrepis.zadosti.filter,
                },
            },
        }),
        setImportOne: (state: ZadostiPlynStore, {payload}: PayloadAction<boolean>): ZadostiPlynStore => ({
            ...state,
            openImportOne: payload,
        }),
        setImportTwo: (state: ZadostiPlynStore, {payload}: PayloadAction<boolean>): ZadostiPlynStore => ({
            ...state,
            openImportTwo: payload,
        }),
    },
});

export const {
    setResetZadostiPlynOBNDavkyFilter,
    setResetZadostiPlynOBNZadostiFilter,
    setResetZadostiPlynRDKDavkyFilter,
    setResetZadostiPlynRDKZadostiFilter,
    setResetZadostiPlynSOPDavkyFilter,
    setResetZadostiPlynSOPZadostiFilter,
    setResetZadostiPlynUPDavkyFilter,
    setResetZadostiPlynUPZadostiFilter,
    setZadostiPlynChangeOBNSelectedLeftMenuType,
    setZadostiPlynChangeOBNTab,
    setZadostiPlynChangeOBNZadostiSelectedLeftMenuType,
    setZadostiPlynChangeRDKSelectedLeftMenuType,
    setZadostiPlynChangeRDKTab,
    setZadostiPlynChangeRDKZadostiSelectedLeftMenuType,
    setZadostiPlynChangeSOPSelectedLeftMenuType,
    setZadostiPlynChangeSOPTab,
    setZadostiPlynChangeSOPZadostiSelectedLeftMenuType,
    setZadostiPlynChangeTab,
    setZadostiPlynChangeUPSelectedLeftMenuType,
    setZadostiPlynChangeUPTab,
    setZadostiPlynChangeUPZadostiSelectedLeftMenuType,
    setZadostiPlynOBNDavkyFilter,
    setZadostiPlynOBNZadostiFilter,
    setZadostiPlynRDKDavkyFilter,
    setZadostiPlynRDKZadostiFilter,
    setZadostiPlynSOPDavkyFilter,
    setZadostiPlynSOPZadostiFilter,
    setZadostiPlynUPDavkyFilter,
    setZadostiPlynUPZadostiFilter,
    setImportOne,
    setImportTwo,
} = zadostiPlynSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const zadostiPlyn = (state: Store) => state.zadosti.plyn;
export const selectZadostiPlyn = createSelector([zadostiPlyn], (zadostiPlyn) => zadostiPlyn);
