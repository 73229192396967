import {PasswordQuality, ValidationError} from '@eon.cz/apollo13-graphql';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {AuthStore, LoginData} from '../../../Store/AuthStore';

const initialState = {
    isFetchingLogin: false,
    errorLogin: undefined,
    isLogouting: false,
    restrictedAccessLoginData: undefined,
    isFetchingLoginConfig: false,
    loginConfigLoginDisabledHtml: undefined,
    isLogin: false,
    reload: false,
    fetchPolicy: false,
    refetchStatus: false,
    passwordQuality: undefined,
} as AuthStore;

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFetchingLogin: (state: AuthStore): AuthStore => ({...state, isFetchingLogin: true, errorLogin: undefined}),

        setSwitchPristup: (state: AuthStore): AuthStore => ({
            ...state,
            isFetchingLogin: true,
            errorLogin: undefined,
            reload: true,
            refetchStatus: false,
        }),

        setChangeLoginStatus: (state: AuthStore): AuthStore => ({...state, isLogin: false, reload: false, refetchStatus: true}),

        setChangeRefetchStatus: (state: AuthStore): AuthStore => ({...state, refetchStatus: false}),

        setPasswordQuality: (state: AuthStore, {payload}: PayloadAction<PasswordQuality>): AuthStore => ({...state, passwordQuality: payload}),

        setFetchedLogin: (state: AuthStore): AuthStore => ({
            ...state,
            isFetchingLogin: false,
            errorLogin: undefined,
            isLogin: true,
        }),

        setErrorFetchedLogin: (state: AuthStore, {payload}: PayloadAction<Pick<ValidationError, 'code' | 'message'>>): AuthStore => ({
            ...state,
            isFetchingLogin: false,
            errorLogin: payload,
        }),

        setRemoveErrorLogin: (state: AuthStore): AuthStore => ({...state, errorLogin: undefined}),

        setStoreLoginData: (state: AuthStore, {payload}: PayloadAction<LoginData>): AuthStore => ({...state, restrictedAccessLoginData: payload}),

        setClearLoginData: (state: AuthStore): AuthStore => ({...state, restrictedAccessLoginData: undefined}),

        setLogouting: (state: AuthStore, {payload}: PayloadAction<boolean>): AuthStore => ({
            ...state,
            isLogouting: payload,
            isLogin: false,
            reload: false,
            fetchPolicy: false,
            refetchStatus: false,
        }),

        setLoginConfigFetching: (state: AuthStore): AuthStore => {
            return {...state, isFetchingLoginConfig: true};
        },

        setLoginConfigFetched: (state: AuthStore, {payload}: PayloadAction<string | undefined>): AuthStore => {
            return {...state, isFetchingLoginConfig: false, loginConfigLoginDisabledHtml: payload};
        },
    },
});

export const {
    setChangeLoginStatus,
    setChangeRefetchStatus,
    setClearLoginData,
    setErrorFetchedLogin,
    setFetchedLogin,
    setFetchingLogin,
    setLoginConfigFetched,
    setLoginConfigFetching,
    setPasswordQuality,
    setRemoveErrorLogin,
    setStoreLoginData,
    setSwitchPristup,
    setLogouting,
} = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example, `useSelector((state: RootState) => state.counter.value)`
export const auth = (state: Store) => state.auth;
export const selectAuth = createSelector([auth], (auth) => auth);
