import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {BiometryTypes, MobileProps, PermissionStatus} from '../model/MobilePropsModel';
import {CommonStore} from '../store/CommonStore';

const initialState: CommonStore = {
    admin: false,
    mobile: {
        scanResult: undefined,
        biometryType: BiometryTypes.None,
        biometryAvailable: false,
        notificationGranted: PermissionStatus.UNDETERMINED,
        mobileNotification: undefined,
        canUseBiometrics: undefined,
        errorMobileLogin: undefined,
    },
    canUseBiometrics: undefined,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAdminData: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => {
            return {...state, admin: payload};
        },
        setMobileData: (state: CommonStore, {payload}: PayloadAction<MobileProps>): CommonStore => {
            return {...state, mobile: {...state.mobile, ...payload}};
        },
        setCanUseBiometricsData: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => {
            return {...state, canUseBiometrics: payload};
        },
        setLocationGrantedData: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => {
            return {...state, mobile: {...state.mobile, locationGranted: payload}};
        },
        setErrorMobileLogin: (state: CommonStore, {payload}: PayloadAction<string | undefined>): CommonStore => {
            return {...state, mobile: {...state.mobile, errorMobileLogin: payload}};
        },
    },
});

export const {setAdminData, setMobileData, setCanUseBiometricsData, setLocationGrantedData, setErrorMobileLogin} = commonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const common = (state: Store) => state.common;
export const selectCommon = createSelector([common], (common) => common);
