import {ApiDate, Komodita} from '@eon.cz/apollo13-graphql';

export const DayOrMonth = {
    DAY: 'DAY',
    MONTH: 'MONTH',
} as const;

export type DayOrMonth = (typeof DayOrMonth)[keyof typeof DayOrMonth];

export type PrilohyKeStazeniFilterModel = {
    readonly datumOd: ApiDate;
    readonly datumDo: ApiDate;
    readonly typ?: string | null;
    readonly komodita: Komodita | undefined | null;
    readonly cisloOp?: string;
    readonly cisloDokladu?: string | null;
    readonly stav?: DanoveDokladyStavPlatby | null;
};
export type TechnickeDetailyOMModel = {
    readonly platnostZacatekSmlouvyOd: ApiDate;
    readonly platnostZacatekSmlouvyDo: ApiDate;
    readonly datumVymenyOd: ApiDate;
    readonly datumVymenyDo: ApiDate;
};

export const DanoveDokladyStavPlatby = {
    VSE: 'VSE',
    CEKA_NA_ZAPLACENI: 'CEKA_NA_ZAPLACENI',
    CEKA_NA_ZAPLACENI_PO_SPLATNOSTI: 'CEKA_NA_ZAPLACENI_PO_SPLATNOSTI',
    CEKA_NA_ZAPLACENI_PRED_SPLATNOSTI: 'CEKA_NA_ZAPLACENI_PRED_SPLATNOSTI',
    UHRAZENO: 'UHRAZENO',
} as const;

export type DanoveDokladyStavPlatby = (typeof DanoveDokladyStavPlatby)[keyof typeof DanoveDokladyStavPlatby];
