import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {RegistrationStore} from '../../../Store/RegistrationStore';
import {RegistrationZakaznikZadatelModel} from '../model';

const initialState = {
    isOpenRegistrationDialog: false,
    registrationModel: {} as RegistrationZakaznikZadatelModel,
    isRegistering: false,
    isMunicipalita: false,
    openInfoDialog: false,
    openInfoDialogMunicipalita: false,
    registrationData: {},
    zadatel: false,
    isVerifyMunicipalita: false,
} as RegistrationStore;

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setRegistrationData: (state: RegistrationStore, {payload}: PayloadAction<{[key: string]: string}>): RegistrationStore => ({
            ...state,
            registrationData: payload,
        }),

        setOpenRegistrationDialog: (state: RegistrationStore, {payload}: PayloadAction<boolean>): RegistrationStore => ({
            ...state,
            isOpenRegistrationDialog: true,
            errorMessages: undefined,
            isRegistering: false,
            zadatel: payload,
        }),

        setCloseRegistrationDialog: (state: RegistrationStore): RegistrationStore => ({
            ...state,
            isOpenRegistrationDialog: false,
            errorMessages: undefined,
            isRegistering: false,
            zadatel: false,
        }),

        setRegister: (state: RegistrationStore): RegistrationStore => ({...state, isRegistering: true, errorMessages: undefined}),
        setVerifyMunicipalita: (state: RegistrationStore, {payload}: PayloadAction<boolean>): RegistrationStore => ({...state, isVerifyMunicipalita: payload}),

        setRegisterSuccess: (state: RegistrationStore): RegistrationStore => ({
            ...state,
            isRegistering: false,
            errorMessages: undefined,
            isOpenRegistrationDialog: false,
            isVerifyMunicipalita: false,
            openInfoDialog: true,
        }),

        setRegistermunicipalitaSuccess: (state: RegistrationStore, {payload}: PayloadAction<boolean>): RegistrationStore => ({
            ...state,
            isRegistering: false,
            errorMessages: undefined,
            isOpenRegistrationDialog: false,
            openInfoDialogMunicipalita: payload,
        }),

        setVerifyRegisterSuccess: (state: RegistrationStore): RegistrationStore => ({
            ...state,
            isMunicipalita: true,
            isVerifyMunicipalita: false,
        }),

        setRegisterSuccessInfo: (state: RegistrationStore): RegistrationStore => ({
            ...state,
            openInfoDialog: false,
            openInfoDialogMunicipalita: false,
            isVerifyMunicipalita: false,
        }),

        setRegisterError: (state: RegistrationStore, {payload}: PayloadAction<string | undefined>): RegistrationStore => ({
            ...state,
            isRegistering: false,
            errorMessages: payload ? [payload] : undefined,
        }),

        setVerifyRegisterError: (state: RegistrationStore, {payload}: PayloadAction<boolean>): RegistrationStore => ({
            ...state,
            isMunicipalita: payload,
        }),

        setRegisterClearError: (state: RegistrationStore): RegistrationStore => ({...state, errorMessages: undefined}),
        setRegisterReset: (): RegistrationStore => ({...initialState}),
    },
});

export const {
    setCloseRegistrationDialog,
    setOpenRegistrationDialog,
    setRegister,
    setRegisterClearError,
    setRegisterError,
    setRegisterReset,
    setRegisterSuccess,
    setRegisterSuccessInfo,
    setRegistermunicipalitaSuccess,
    setRegistrationData,
    setVerifyRegisterError,
    setVerifyRegisterSuccess,
    setVerifyMunicipalita,
} = registrationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const registration = (state: Store) => state.registration;
export const selectRegistration = createSelector([registration], (registration) => registration);
