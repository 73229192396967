const VSE = 'vse';

// constants for better readability within context
export const ZADOSTI_DAVKY_TYP_ZADOSTI_VSE = VSE;
export const ZADOSTI_STAV_VSE = VSE;
export const ZADOSTI_TYP_VSE = VSE;
export const ZADOSTI_TYP_ZMENY_DODAVATELE_VSE = VSE;
export const ZADOSTI_STAV_RESENI_VSE = VSE;
export const ZADOSTI_DUVOD_VSE = VSE;
export const ZADOSTI_OPRAVNA_VSE = VSE;
export const ZADOSTI_NAPETOVA_UROVEN_VSE = VSE;
export const ZADOSTI_TYP_PRIPOJENI_VSE = VSE;
