import {Button} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {useMatches} from '../../Common/services/PageService';

export const CookiesButton = () => {
    const matches = useMatches();

    const toggleCookiesDialog = () => {
        window?.UC_UI?.showFirstLayer();
    };

    return (
        <Button
            variant="contained"
            sx={{
                fontSize: 15,
                borderRadius: '10px 10px 0px 0px',
                marginLeft: matches ? -20 : undefined,
                backgroundColor: 'common.white',
                color: 'secondary.main',
            }}
            onClick={toggleCookiesDialog}
        >
            <FormattedMessage id={Lang.LOGIN_COOKIES_SETTING} />
        </Button>
    );
};
