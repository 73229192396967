import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {TableStore} from '../store/TableStore';

const initialState: TableStore = {
    page: 0,
    rowsPerPage: 10,
};

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPage: (state: TableStore, {payload}: PayloadAction<number>): TableStore => {
            return {...state, page: payload};
        },
        setRowsPerPage: (state: TableStore, {payload}: PayloadAction<number>): TableStore => {
            return {...state, rowsPerPage: payload};
        },
        resetTableStore: (): TableStore => {
            return initialState;
        },
    },
});

export const {setPage, setRowsPerPage, resetTableStore} = tableSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const table = (state: Store) => state.table;
export const selectCommon = createSelector([table], (table) => table);
