import {ZadostDavkaStav} from '@eon.cz/apollo13-graphql';

import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {ZadostiElektrinaStore} from '../../../../Store/ZadostiStore';
import {ZADOSTI_DAVKY_TYP_ZADOSTI_VSE, ZADOSTI_DUVOD_VSE, ZADOSTI_STAV_RESENI_VSE, ZADOSTI_STAV_VSE, ZADOSTI_TYP_VSE} from '../../common/constants';
import {ZadostiMenuItemModel} from '../../model';

export const initialState = {
    activeTab: 0,
    ramcoveSmlouvy: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                typZadosti: ZADOSTI_DAVKY_TYP_ZADOSTI_VSE,
                cisloDavkyFulltext: null,
                datumOdeslaniDo: null,
                datumOdeslaniOd: null,
                datumVytvoreniDo: null,
                datumVytvoreniOd: null,
                vytvoril: null,
            },
            zahrnoutChybne: false,
        },
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eanFulltext: null,
                stav: ZADOSTI_STAV_VSE,
                typ: ZADOSTI_TYP_VSE,
                duvod: ZADOSTI_DUVOD_VSE,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                terminZahajeniDistribuceOd: null,
                terminZahajeniDistribuceDo: null,
                terminUkonceniDistribuceOd: null,
                terminUkonceniDistribuceDo: null,
                vytvoril: null,
            },
            zahrnoutChybne: false,
        },
    },
    preruseniObnoveni: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                cisloDavkyFulltext: null,
                typZadosti: ZADOSTI_DAVKY_TYP_ZADOSTI_VSE,
                datumOdeslaniDo: null,
                datumOdeslaniOd: null,
                datumVytvoreniDo: null,
                datumVytvoreniOd: null,
                vytvoril: null,
            },
        },
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stav: ZADOSTI_STAV_VSE,
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eanFulltext: null,
                typ: ZADOSTI_TYP_VSE,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                pozadovanyTerminOd: null,
                pozadovanyTerminDo: null,
                vytvoril: null,
            },
        },
    },
    sopPrepis: {
        activeTab: 0,
        davky: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                cisloDavkyFulltext: null,
                datumOdeslaniDo: null,
                datumOdeslaniOd: null,
                datumVytvoreniDo: null,
                datumVytvoreniOd: null,
                vytvoril: null,
            },
        },
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stav: ZADOSTI_STAV_VSE,
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cisloDavkyFulltext: null,
                cislo: null,
                eanFulltext: null,
                napetovaHladina: null,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                zahajeniOdberuOd: null,
                zahajeniOdberuDo: null,
                vytvoril: null,
            },
        },
    },
    sopPrepisZmenaJistice: {
        activeTab: 0,
        zadosti: {
            selectedLeftMenuType: 'KONCEPT',
            filter: {
                stav: ZADOSTI_STAV_VSE,
                stavReseni: ZADOSTI_STAV_RESENI_VSE,
                cislo: null,
                eanFulltext: null,
                datumVytvoreniOd: null,
                datumVytvoreniDo: null,
                datumOdeslaniOd: null,
                datumOdeslaniDo: null,
                vytvoril: null,
            },
        },
    },
} as ZadostiElektrinaStore;

export const zadostiElektrinaSlice = createSlice({
    name: 'zadostiElektrina',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setZadostiElektrinaChangeTab: (state: ZadostiElektrinaStore, {payload}: PayloadAction<number>): ZadostiElektrinaStore => ({
            ...state,
            activeTab: payload,
        }),

        setZadostiElektrinaChangeRSDavkySelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostDavkaStav>,
        ): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                davky: {
                    ...state.ramcoveSmlouvy.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangeRSZadostiSelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostiMenuItemModel>,
        ): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                zadosti: {
                    ...state.ramcoveSmlouvy.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangeRSTab: (state: ZadostiElektrinaStore, {payload}: PayloadAction<number>): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                activeTab: payload,
            },
        }),
        setZadostiElektrinaRSDavkyFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                davky: {
                    ...state.ramcoveSmlouvy.davky,
                    filter: {...payload},
                },
            },
        }),
        setResetZadostiElektrinaRSDavkyFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                davky: {
                    ...state.ramcoveSmlouvy.davky,
                    filter: initialState.ramcoveSmlouvy.davky.filter,
                },
            },
        }),
        setZadostiElektrinaRSZadostiFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                zadosti: {
                    ...state.ramcoveSmlouvy.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiElektrinaRSZadostiFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            ramcoveSmlouvy: {
                ...state.ramcoveSmlouvy,
                zadosti: {
                    ...state.ramcoveSmlouvy.zadosti,
                    filter: initialState.ramcoveSmlouvy.zadosti.filter,
                },
            },
        }),

        // Preruseni/Obnoveni
        setZadostiElektrinaChangePODavkySelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostDavkaStav>,
        ): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                davky: {
                    ...state.preruseniObnoveni.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangePOZadostiSelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostiMenuItemModel>,
        ): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                zadosti: {
                    ...state.preruseniObnoveni.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangePOTab: (state: ZadostiElektrinaStore, {payload}: PayloadAction<number>): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                activeTab: payload,
            },
        }),
        setZadostiElektrinaPODavkyiFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                davky: {
                    ...state.preruseniObnoveni.davky,
                    filter: payload,
                },
            },
        }),
        setResetZadostiElektrinaPODavkyFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                davky: {
                    ...state.preruseniObnoveni.davky,
                    filter: initialState.preruseniObnoveni.davky.filter,
                },
            },
        }),
        setZadostiElektrinaPOZadostiFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                zadosti: {
                    ...state.preruseniObnoveni.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiElektrinaPOZadostiFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            preruseniObnoveni: {
                ...state.preruseniObnoveni,
                zadosti: {
                    ...state.preruseniObnoveni.zadosti,
                    filter: initialState.preruseniObnoveni.zadosti.filter,
                },
            },
        }),

        // Sop
        setZadostiElektrinaChangeSOPDavkySelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostDavkaStav>,
        ): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                davky: {
                    ...state.sopPrepis.davky,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangeSOPZadostiSelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostiMenuItemModel>,
        ): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                zadosti: {
                    ...state.sopPrepis.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangeSOPTab: (state: ZadostiElektrinaStore, {payload}: PayloadAction<number>): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                activeTab: payload,
            },
        }),
        setZadostiElektrinaSOPDavkyFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                davky: {
                    ...state.sopPrepis.davky,
                    filter: payload,
                },
            },
        }),
        setResetZadostiElektrinaSOPDavkyFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                davky: {
                    ...state.sopPrepis.davky,
                    filter: initialState.sopPrepis.davky.filter,
                },
            },
        }),
        setZadostiElektrinaSOPZadostiFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                zadosti: {
                    ...state.sopPrepis.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiElektrinaSOPZadostiFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            sopPrepis: {
                ...state.sopPrepis,
                zadosti: {
                    ...state.sopPrepis.zadosti,
                    filter: initialState.sopPrepis.zadosti.filter,
                },
            },
        }),

        // Sop změna jističe
        setZadostiElektrinaChangeSOPZmenaJisticeDavkySelectedLeftMenuType: (
            state: ZadostiElektrinaStore,
            {payload}: PayloadAction<ZadostiMenuItemModel>,
        ): ZadostiElektrinaStore => ({
            ...state,
            sopPrepisZmenaJistice: {
                ...state.sopPrepisZmenaJistice,
                zadosti: {
                    ...state.sopPrepisZmenaJistice.zadosti,
                    selectedLeftMenuType: payload,
                },
            },
        }),
        setZadostiElektrinaChangeSOPZmenaJisticeTab: (state: ZadostiElektrinaStore, {payload}: PayloadAction<number>): ZadostiElektrinaStore => ({
            ...state,
            sopPrepisZmenaJistice: {
                ...state.sopPrepisZmenaJistice,
                activeTab: payload,
            },
        }),
        setZadostiElektrinaSOPZmenaJisticeZadostiFilter: (state: ZadostiElektrinaStore, {payload}: PayloadAction<any>): ZadostiElektrinaStore => ({
            ...state,
            sopPrepisZmenaJistice: {
                ...state.sopPrepisZmenaJistice,
                zadosti: {
                    ...state.sopPrepisZmenaJistice.zadosti,
                    filter: payload,
                },
            },
        }),
        setResetZadostiElektrinaSOPZmenaJisticeZadostiFilter: (state: ZadostiElektrinaStore): ZadostiElektrinaStore => ({
            ...state,
            sopPrepisZmenaJistice: {
                ...state.sopPrepisZmenaJistice,
                zadosti: {
                    ...state.sopPrepisZmenaJistice.zadosti,
                    filter: initialState.sopPrepisZmenaJistice.zadosti.filter,
                },
            },
        }),
        setZadostiImportOne: (state: ZadostiElektrinaStore, {payload}: PayloadAction<boolean>): ZadostiElektrinaStore => ({
            ...state,
            openImportOne: payload,
        }),
        setZadostiImportTwo: (state: ZadostiElektrinaStore, {payload}: PayloadAction<boolean>): ZadostiElektrinaStore => ({
            ...state,
            openImportTwo: payload,
        }),
    },
});

export const {
    setResetZadostiElektrinaPODavkyFilter,
    setZadostiElektrinaPODavkyiFilter,
    setResetZadostiElektrinaPOZadostiFilter,
    setResetZadostiElektrinaRSDavkyFilter,
    setResetZadostiElektrinaRSZadostiFilter,
    setResetZadostiElektrinaSOPDavkyFilter,
    setZadostiElektrinaSOPDavkyFilter,
    setResetZadostiElektrinaSOPZadostiFilter,
    setResetZadostiElektrinaSOPZmenaJisticeZadostiFilter,
    setZadostiElektrinaChangePODavkySelectedLeftMenuType,
    setZadostiElektrinaChangePOTab,
    setZadostiElektrinaChangePOZadostiSelectedLeftMenuType,
    setZadostiElektrinaChangeRSDavkySelectedLeftMenuType,
    setZadostiElektrinaChangeRSTab,
    setZadostiElektrinaChangeRSZadostiSelectedLeftMenuType,
    setZadostiElektrinaChangeSOPDavkySelectedLeftMenuType,
    setZadostiElektrinaChangeSOPTab,
    setZadostiElektrinaChangeSOPZadostiSelectedLeftMenuType,
    setZadostiElektrinaChangeSOPZmenaJisticeDavkySelectedLeftMenuType,
    setZadostiElektrinaChangeSOPZmenaJisticeTab,
    setZadostiElektrinaChangeTab,
    setZadostiElektrinaPOZadostiFilter,
    setZadostiElektrinaRSDavkyFilter,
    setZadostiElektrinaRSZadostiFilter,
    setZadostiElektrinaSOPZadostiFilter,
    setZadostiElektrinaSOPZmenaJisticeZadostiFilter,
    setZadostiImportOne,
    setZadostiImportTwo,
} = zadostiElektrinaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const zadostiElektrina = (state: Store) => state.zadosti.elektrina;
export const selectZadostiElektrina = createSelector([zadostiElektrina], (zadostiElektrina) => zadostiElektrina);
