export const OdberneMistoDetailTabValueModel = {
    HLAVNI: 'hlavni',
    SMLOUVA_O_PRIPOJENI: 'smlouva_o_pripojeni',
    NOTIFIKACE_OZNAMOVANI: 'notifikace_oznamovani',
    HISTORIE: 'historie',
    HISTORIE_C123BNN: 'historie_c123bnn',
    HISTORIE_ODECTU_B: 'historie_odectu_b',
    KAPACITA: 'kapacita',
    MNOZSTVI: 'mnozstvi',
    SMART_MERENI: 'smart_mereni',
    HDO: 'hdo',
    NOTIFIKACE: 'notifikace',
} as const;

export type OdberneMistoDetailTabValueModel = (typeof OdberneMistoDetailTabValueModel)[keyof typeof OdberneMistoDetailTabValueModel];
