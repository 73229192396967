import {ReactNode} from 'react';
import {PageRouteModel} from './PageRouteModel';

export const MobileModalModel = {
    DASHBOARD: 'dashboard',
    KOMODITA: 'komodita',
    KONTAKTY: 'kontakty',
    PROFIL: 'profil',
    MENU: 'menu',
    PRISTUPY: 'pristupy',
} as const;
export type MobileModalModel = (typeof MobileModalModel)[keyof typeof MobileModalModel];

export type AppBottomNavigationModel = {
    readonly value: string;
    readonly label: ReactNode | string;
    readonly icon: string | ReactNode;
    readonly route?: PageRouteModel;
    readonly modal?: MobileModalModel | undefined;
    readonly showLabel?: boolean;
    readonly allowInLimitedMode?: boolean;
    readonly isVisible?: boolean;
};
