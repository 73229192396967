import * as React from 'react';

import {Komodita} from '@eon.cz/apollo13-graphql';

/**
 * Kontext obsahující pouze komoditu. Oddělený od ProfileContext protože se musí získávat výše ve stromu komponent než profil,
 * následně je komodita zkopírována i ProfileContext.
 */
export interface KomoditaContextType {
    readonly komodita?: Komodita | null;
}

export const KomoditaContext = React.createContext<KomoditaContextType>({
    komodita: null,
});
