import createCache from '@emotion/cache';
import {createTheme} from '@mui/material';
import {PaletteOptions} from '@mui/material/styles/createPalette';
import {createSpacing} from '@mui/system';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import {useEffect, useMemo, useState} from 'react';

export const AppTheme = {
    DARK: 'dark',
    LIGHT: 'light',
} as const;

export type AppThemeType = keyof typeof AppTheme;

export const cache = createCache({key: 'css', prepend: true});

export const palette = {
    mode: AppTheme.LIGHT,
    primary: {
        main: '#1200E0',
        light: '#ffffff',
        dark: '#261B62',
        contrastText: '#fff',
    },
    secondary: {
        main: '#261B62',
        light: '#78e0ed',
        dark: '#007e8b',
        contrastText: '#fff',
    },
    error: {
        main: '#e13019',
    },
    grey: {
        '500': '#8A8A8E',
    },
    custom: {
        green: {
            main: '#15C851',
            light: '#80e27e',
            dark: '#087f23',
            contrastText: '#fff',
        },
        orange: {
            main: '#ff9800',
            light: '#ffc947',
            dark: '#c66900',
            contrastText: '#000',
        },
        indigo: {
            main: '#3f51b5',
            light: '#757de8',
            dark: '#002984',
            contrastText: '#fff',
        },
        brown: {
            main: '#795548',
            light: '#a98274',
            dark: '#4b2c20',
            contrastText: '#fff',
        },
        purple: {
            main: '#9E54EE',
            light: '#c48bff',
            dark: '#6a1b9a',
            contrastText: '#fff',
        },
    },
    background: {
        paper: '#ffffff',
        default: '#f1f1f1',
    },
} satisfies PaletteOptions;

export const theme = createTheme({
    palette,
});

const customBreakpointValues = {
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
    },
};

const breakpoints = createBreakpoints({...customBreakpointValues});
const spacing = createSpacing(8);
export const useTheme = () => {
    const [preference, setPreference] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState<string | null>(() => (typeof window === 'undefined' ? AppTheme.LIGHT : window.localStorage.getItem('theme')));

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
            setPreference(prefersDarkMode.matches);
        }
    }, []);

    const changeTheme = (theme: AppThemeType) => {
        localStorage.setItem('theme', theme);
        setDarkMode(theme);
    };
    // let theme = createTheme();
    const theme = useMemo(
        () =>
            createTheme({
                breakpoints: {
                    ...customBreakpointValues,
                },
                typography: {
                    fontFamily: '"EONBrixSans", arial, sans-serif',
                },
                components: {
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: palette?.secondary?.main,
                                '&:focus': {
                                    color: palette?.primary?.main,
                                },
                            },
                        },
                    },
                    MuiIcon: {
                        styleOverrides: {
                            root: {
                                color: palette?.secondary?.main,
                            },
                        },
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                borderRadius: 28,
                                textTransform: 'none',
                                padding: '14px 32px',
                                fontWeight: 'bolder',
                                lineHeight: '18px',
                            },
                            text: {
                                padding: '14px 32px',
                            },
                            outlined: {
                                '&:hover': {
                                    backgroundColor: palette?.primary?.main,
                                    color: 'white',
                                },
                            },
                        },
                    },
                    MuiIconButton: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                                width: 48,
                                height: 48,
                            },
                        },
                    },
                    MuiCheckbox: {
                        styleOverrides: {
                            colorSecondary: {
                                '&$checked': {
                                    color: palette?.secondary?.main,
                                },
                            },
                        },
                    },
                    MuiFormLabel: {
                        styleOverrides: {
                            root: {
                                '&$focused': {
                                    color: palette.primary ? palette.primary.dark : undefined,
                                },
                            },
                        },
                    },
                    MuiInput: {
                        styleOverrides: {
                            underline: {
                                '&:after': {
                                    borderBottom: `2px solid ${palette.grey[500]}`,
                                },
                            },
                            input: {
                                // color: palette.primary.main,
                                '&::placeholder': {
                                    color: 'GrayText',
                                },
                            },
                            root: {
                                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                    borderBottomColor: `${palette.grey[500]}`,
                                },
                            },
                        },
                    },
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                maxWidth: 900,
                                border: `1px solid ${palette.primary.main} `,
                                borderRadius: 5,
                                backgroundColor: palette.background?.paper,
                                color: palette.secondary.main,
                                fontSize: 13,
                            },
                        },
                    },
                    MuiDialog: {
                        defaultProps: {
                            defaultValue: 'mobile' as 'mobile' | 'desktop' | undefined,
                        },
                        styleOverrides: {
                            paperFullWidth: ({ownerState}) =>
                                ownerState.defaultValue === 'mobile'
                                    ? {
                                          [breakpoints.down('sm')]: {
                                              width: '100%',
                                          },
                                      }
                                    : undefined,
                            container: ({ownerState}) =>
                                ownerState.defaultValue === 'mobile'
                                    ? {
                                          [breakpoints.down('sm')]: {
                                              alignItems: 'flex-end',
                                          },
                                      }
                                    : undefined,
                            paper: ({ownerState}) =>
                                ownerState.defaultValue === 'mobile'
                                    ? {
                                          [breakpoints.down('sm')]: {
                                              margin: 0,
                                              maxWidth: '100%',
                                              width: '100%',
                                              borderRadius: spacing(3, 3, 0, 0),
                                              scrollbarWidth: 'none',
                                          },
                                      }
                                    : undefined,
                        },
                    },
                    MuiDialogContent: {
                        styleOverrides: {
                            root: () => ({
                                [breakpoints.down('sm')]: {
                                    scrollbarWidth: 'none',
                                },
                            }),
                        },
                    },
                    MuiDialogActions: {
                        styleOverrides: {
                            root: () => ({
                                [breakpoints.down('sm')]: {
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    gap: 2,
                                },
                            }),
                            spacing: {
                                '& > :not(:first-of-type)': {
                                    marginLeft: 0,
                                },
                            },
                        },
                    },
                },
                palette: {
                    ...palette,
                    mode: preference && darkMode === AppTheme.DARK ? AppTheme.DARK : AppTheme.LIGHT,
                    text: {
                        primary: palette.secondary.main,
                    },
                },
            }),
        [darkMode, preference],
    );
    return {theme, changeTheme};
};
