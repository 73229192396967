import {DodatekStav} from '@eon.cz/apollo13-graphql';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {DodatkyStore} from '../../../Store/DodatkyStore';
import {DodatkyFilterModel} from '../model';

export const initialState = {
    filter: {
        cislo: null,
        cisloSmlouvy: null,
        datumVytvoreniOd: null,
        datumVytvoreniDo: null,
        stav: DodatekStav.CEKA_NA_POTVRZENI_OBCHODNIKEM,
    },
} as DodatkyStore;

export const dodatkySlice = createSlice({
    name: 'dodatky',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setDodatkyFilter: (state: DodatkyStore, {payload}: PayloadAction<DodatkyFilterModel>): DodatkyStore => ({
            ...state,
            filter: {...state.filter, ...payload},
        }),

        setClearDodatkyFilter: (state: DodatkyStore): DodatkyStore => ({
            ...state,
            filter: initialState.filter,
        }),
    },
});

export const {setClearDodatkyFilter, setDodatkyFilter} = dodatkySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const dodatky = (state: Store) => state.dodatky;
export const selectDodatky = createSelector([dodatky], (dodatky) => dodatky);
