import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {RootActionType} from '../../../RootAction';
import {MobileProps} from '../model/MobilePropsModel';
import {setAdminData, setCanUseBiometricsData, setErrorMobileLogin, setLocationGrantedData, setMobileData} from '../reducers/CommonReducers';

type CommonActionType = {
    setAdmin: (value: boolean) => void;
    setMobileProps: (value: MobileProps) => void;
    setLocationGranted: (value: boolean) => void;
    setCanUseBiometrics: (value: boolean) => void;
    setErrorFetchedLogin: (value: string | undefined) => void;
    resetStore: () => void;
};

/**
 * Custom hook pro redux akci společnou napříč aplikací
 *
 * @return {*}  {CommonActionType}
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();

    return {
        setAdmin: (payload) => {
            dispatch(setAdminData(payload));
        },
        setCanUseBiometrics: (payload) => {
            dispatch(setCanUseBiometricsData(payload));
        },
        resetStore: () => {
            // Reset store
            window.localStorage.clear();

            // Reset store
            dispatch({type: RootActionType.LOGOUT, payload: undefined});
        },
        setMobileProps: (payload) => {
            dispatch(setMobileData(payload));
        },
        setLocationGranted: (payload) => {
            dispatch(setLocationGrantedData(payload));
        },
        setErrorFetchedLogin: (payload) => {
            dispatch(setErrorMobileLogin(payload));
        },
    };
};

const CommonAction = {
    logout: (payload: boolean) => (dispatch: Dispatch) => {
        dispatch(setAdminData(payload));
    },
    resetStore: (dispatch: Dispatch) => {
        // Reset store
        window.localStorage.clear();

        // Reset store
        dispatch({type: RootActionType.LOGOUT, payload: undefined});
    },
    setMobileProps: (payload: MobileProps) => (dispatch: Dispatch) => {
        dispatch(setMobileData(payload));
    },
    setLocationGranted: (payload: boolean) => (dispatch: Dispatch) => {
        dispatch(setLocationGrantedData(payload));
    },
    setErrorFetchedLogin: (payload: string | undefined) => (dispatch: Dispatch) => {
        dispatch(setErrorMobileLogin(payload));
    },
};
/**
 * Common action creator je možné volat i mimo komponentu. Využití v apolloIniti
 *
 * @param {Dispatch} dispatch
 */
export const CommonActionCreator = (dispatch: Dispatch) => bindActionCreators({...CommonAction}, dispatch);
