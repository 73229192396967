export const PrilohyKeStazeniStav = {
    SESTAVY_KE_STAZENI: 'SESTAVY_KE_STAZENI',
    DANOVE_DOKLADY: 'DANOVE_DOKLADY',
    TECHNICKE_DETAILY_OM: 'TECHNICKE_DETAILY_OM',
    MONTAZNI_LISTY: 'MONTAZNI_LISTY',
} as const;

export type PrilohyKeStazeniStav = (typeof PrilohyKeStazeniStav)[keyof typeof PrilohyKeStazeniStav];

export type PrilohyKeStazeniMenuItemModel = PrilohyKeStazeniStav;
