import {Komodita, Maybe, PovoleneTypyPriloh, Ucet, UcetSpravceZmocnenecPristup} from '@eon.cz/apollo13-graphql';
import {createContext} from 'react';

export type AdminContext = {
    readonly adminId: string;
    readonly readOnly: boolean;
};
export interface PageContextType {
    /**
     * Informace o přihlášeném uživateli
     */
    readonly me?: Ucet | null;
    /**
     * Informace o přihlášeném uživateli
     */
    readonly pristupy?: Maybe<UcetSpravceZmocnenecPristup>[];
    /**
     * Aktuálně vybraná komodita
     */
    readonly komodita?: Komodita | null;
    /**
     * Zda je uživatel v omezeném režimu
     */
    readonly loggedInRestrictedMode: boolean;
    /**
     * Seznam povolených typů příloh
     */
    readonly povoleneTypyPriloh: {[key: string]: PovoleneTypyPriloh};
    readonly adminContext: AdminContext;
}

/**
 * Globální kontext poskytující důležité údaje kdekoliv v aplikaci
 */
export const PageContext = createContext<PageContextType>({
    me: null,
    pristupy: undefined,
    komodita: null,
    loggedInRestrictedMode: false,
    povoleneTypyPriloh: {},
    adminContext: {adminId: '', readOnly: false},
});
