/**
 * Získá mocninu čísla 10.
 *
 * @param {number} power - mocnitel čísla 10
 */
export const getPowerOfTen = (power: number) => 10 ** power;

/**
 * Provede součet dvou desetinných čísel s přesností na několik desetinných míst.
 *
 * POZOR: Funguje správně pouze na několik desetinných míst a je lepší nejprve otestovat, zda funkce plně odpovídá use-casu a vrací správné výsledky.
 *
 * @param {number} n1 - první desetinné číslo
 * @param {number} n2 - druhé desetinné číslo
 * @param {number} precision - mocnina čísla, která je následně použita jako počet desetinných míst (default = 1)
 */
export const sumFloats = (n1: number | null | undefined, n2: number, precision: number = 1) => {
    // Získáme n-tou mocninu 10, která určuje počet desetinných míst.
    const power = getPowerOfTen(precision);
    return ((n1 ?? 1) * power + n2 * power) / power;
};

/**
 * Provede součet dvou desetinných čísel s přesností na několik desetinných míst s vynucením zachování počtu desetinných míst.
 *
 * Rozdíl oproti funkci `sumFloats` je, že tato vrací `string`. Výsledek funkce je lepší pro prezentaci v UI.
 *
 * @param {number} n1 - první desetinné číslo
 * @param {number} n2 - druhé desetinné číslo
 * @param {number} precision - mocnina čísla, která je následně použita jako počet desetinných míst (default = 1), počet desetinných míst je vynucen
 */
export const sumFloatsWithForcedPrecision = (n1: number, n2: number, precision = 1) => {
    // Získáme součet desetinných čísel.
    const result = sumFloats(n1, n2, precision);
    // Vynutí zachování počtu desetinných míst.
    // Tedy pokud chceme 0.1 na tři desetinná místa, dostaneme '0.100'.
    return result.toFixed(precision);
};

/**
 * Vynucuje přesnost na uvedený počet desetinných míst u desetinných čísel končících 0.
 *
 * Př.:
 * 0.1 s přesností 2 desetinných míst -> '0.10'
 *
 * 0.1 s přesností 3 desetinných míst -> '0.100'
 *
 * 0.10 s přesností 3 desetinných míst -> '0.100'
 *
 * 0.100 s přesností 2 desetinných míst -> '0.10'
 *
 * @param {number} n - desetinné číslo
 * @param {number} precision - přesnost udávající počet desetinných míst
 */
export const floatForcePrecision = (n: number, precision: number) => n?.toFixed(precision);

export const parseDecimal = (value: string | number | undefined | null, precision?: number) => {
    if (typeof value === 'number' && !precision) {
        return value;
    }
    if (typeof value === 'number' && precision) {
        return Number(floatForcePrecision(value, precision));
    }
    if (typeof value === 'string') {
        const parsedValue = value.includes(',') ? value.replace(',', '.') : value;
        if (precision) {
            return Number(floatForcePrecision(parseFloat(parsedValue), precision));
        }
        return Number(parseFloat(parsedValue));
    }
    return 0;
};
