import {SamoodecetDavkaStav} from '@eon.cz/apollo13-graphql';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../../Store';
import {OdectyElektrinaStore, OdectyPlynStore} from '../../../../Store/OdectyStore';
import {ODECTY_STAV_RESENI_VSE, ODECTY_STAV_VSE} from '../../common/constans';
import {OdectyPodaniDavkaModel, SamoodectyMenuItemModel} from '../../common/model';

export const initialStatePlyn = {
    activeTab: 0,
    davka: {
        id: null,
        cisloDavky: null,
        stav: null,
        samoodecty: undefined,
    } as OdectyPodaniDavkaModel,
    davky: {
        selectedLeftMenuType: 'KONCEPT',
        importHromadne: false,
        filter: {
            datumVytvoreniOd: null,
            datumVytvoreniDo: null,
            cisloDavkyFulltext: null,
            datumOdeslaniOd: null,
            datumOdeslaniDo: null,
            vytvoril: null,
        },
    },
    odecty: {
        selectedLeftMenuType: 'KONCEPT',
        importJednotlive: false,
        filter: {
            datumVytvoreniOd: null,
            cislo: null,
            datumVytvoreniDo: null,
            eicFulltext: null,
            cisloDavkyFulltext: null,
            stav: ODECTY_STAV_VSE,
            stavReseni: ODECTY_STAV_RESENI_VSE,
            cisloPristroje: null,
            duvod: null,
            datumOdeslaniOd: null,
            datumOdeslaniDo: null,
            vytvoril: null,
        },
    },
} as OdectyPlynStore;

export const odectyPlynSlice = createSlice({
    name: 'odectyPlyn',
    initialState: initialStatePlyn,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setOdectyPlynDavkaData: (state: OdectyPlynStore, {payload}: PayloadAction<OdectyPodaniDavkaModel>): OdectyPlynStore => ({
            ...state,
            davka: {
                id: payload.id,
                cisloDavky: payload.cisloDavky,
                stav: payload.stav,
                samoodecty: payload.samoodecty,
            },
        }),
        setResetOdectyPlynDavkaData: (state: OdectyPlynStore): OdectyPlynStore => ({
            ...state,
            davka: initialStatePlyn.davka,
        }),
        // Plyn - prehled davek
        setOdectyPlynDavkaFilter: (state: OdectyPlynStore, {payload}: PayloadAction<any>): OdectyPlynStore => ({
            ...state,
            davky: {
                ...state.davky,
                filter: payload,
            },
        }),

        setResetOdectyPlynDavkaFilter: (state: OdectyPlynStore): OdectyPlynStore => ({
            ...state,
            davky: {
                ...state.davky,
                filter: initialStatePlyn.davky.filter,
            },
        }),

        // Plyn - prehled odectu
        setOdectyPlynOdectyFilter: (state: OdectyPlynStore, {payload}: PayloadAction<any>): OdectyPlynStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                filter: payload,
            },
        }),

        setResetOdectyPlynOdectyFilter: (state: OdectyPlynStore): OdectyPlynStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                filter: initialStatePlyn.odecty.filter,
            },
        }),
        setChangeOdectyDavkyPlynSelectedLeftMenuType: (state: OdectyPlynStore, {payload}: PayloadAction<SamoodecetDavkaStav>): OdectyPlynStore => ({
            ...state,
            davky: {
                ...state.davky,
                selectedLeftMenuType: payload,
            },
        }),
        setChangeOdectyOdectyPlynSelectedLeftMenuType: (state: OdectyPlynStore, {payload}: PayloadAction<SamoodectyMenuItemModel>): OdectyPlynStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                selectedLeftMenuType: payload,
            },
        }),
        setChangeOdectyPlynTab: (state: OdectyPlynStore, {payload}: PayloadAction<number>): OdectyPlynStore => ({
            ...state,
            activeTab: payload,
        }),
        setOdectyPlynImportHromadne: (state: OdectyElektrinaStore, {payload}: PayloadAction<boolean>): OdectyElektrinaStore => ({
            ...state,
            davky: {
                ...state.davky,
                importHromadne: payload,
            },
        }),
        setOdectyPlynImportJednotlive: (state: OdectyElektrinaStore, {payload}: PayloadAction<boolean>): OdectyElektrinaStore => ({
            ...state,
            odecty: {
                ...state.odecty,
                importJednotlive: payload,
            },
        }),
    },
});

export const {
    setChangeOdectyDavkyPlynSelectedLeftMenuType,
    setChangeOdectyOdectyPlynSelectedLeftMenuType,
    setChangeOdectyPlynTab,
    setOdectyPlynDavkaData,
    setOdectyPlynDavkaFilter,
    setOdectyPlynOdectyFilter,
    setResetOdectyPlynDavkaData,
    setResetOdectyPlynDavkaFilter,
    setResetOdectyPlynOdectyFilter,
    setOdectyPlynImportHromadne,
    setOdectyPlynImportJednotlive,
} = odectyPlynSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const odectyPlyn = (state: Store) => state.odecty.plyn;
export const selectOdectyPlyn = createSelector([odectyPlyn], (odectyPlyn) => odectyPlyn);
